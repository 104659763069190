import { ValueOpt } from "best-common-react";
import { Role } from "../types/Role";

export function getUserRoleOption(roles: Role[]): ValueOpt<Role> {
  if (roles.some((role) => role.name === "ROLE_ADMIN")) {
    return { label: "Admin", value: roles.find((role: Role) => role.name === "ROLE_ADMIN") };
  } else if (roles.some((role) => role.name === "ROLE_COORDINATOR")) {
    return { label: "Coordinator", value: roles.find((role: Role) => role.name === "ROLE_COORDINATOR") };
  } else {
    return { label: "Basic", value: roles.find((role: Role) => role.name === "ROLE_BASIC") };
  }
}

export function getUserRoleText(role: Role): string {
  if (role.name === "ROLE_ADMIN") {
    return "Admin";
  } else if (role.name === "ROLE_COORDINATOR") {
    return "Coordinator";
  } else {
    return "Basic";
  }
}
