import { AlertConstants, Button, Checkbox, FormColumn, useAlerts } from "best-common-react";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AdminSuiteRequestStateDTO, SuiteRequestDTO } from "../../../types/SuiteRequest";
import { AdminSuiteActionsProps } from "./AdminSuiteRequestsActions";

const AdminSuiteCompleteAction = ({ request, actionState, setActionState }: AdminSuiteActionsProps) => {
  const { addAlert } = useAlerts();
  const createCopyText = (request: SuiteRequestDTO, _actionState: AdminSuiteRequestStateDTO): string => {
    return `
Payment Info 
Name: ${request.paymentName}
Business: ${request.paymentBusinessName}
Email: ${request.paymentEmail}
Phone: ${request.phoneNumber}
    `;
  };

  return (
    <FormColumn width={2}>
      <div className="d-flex flex-column">
        <CopyToClipboard
          text={createCopyText(request, actionState)}
          onCopy={() => {
            addAlert({
              type: AlertConstants.TYPES.SUCCESS,
              text: "Copied to clipboard",
            });
          }}
        >
          <Button variant="link" className="align-self-end">
            Copy Request Details
          </Button>
        </CopyToClipboard>
        <Checkbox
          id="email-details-sent-to-club"
          label="Email & Details Sent To Club"
          className="align-self-start"
          checked={actionState.emailDetailsSentToClub}
          onChange={(value: boolean) => {
            setActionState({
              ...actionState,
              emailDetailsSentToClub: value,
            });
          }}
        />
      </div>
    </FormColumn>
  );
};

export default AdminSuiteCompleteAction;
