import { Checkbox, Email, FormColumn, HtmlRender } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUserBillings } from "../../api/RequesTixApi";
import { useAuth } from "../../contexts/AuthContext";
import { PurchaseOpportunityDTO, PurchaseOpportunityEntryRecordDTO } from "../../types/PurchaseOpportunity";
import { BillingDTO } from "../../types/UserBilling";
import CreditCardSelect from "../payment/CreditCardSelect";
import PurchaseOpportunityGameSelect from "./PurchaseOpportunityGameSelect";

const Form = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 1rem;
  }
  @media (min-width: 768px) {
    margin-left: 1rem;
  }
`;

type PurchaseOpportunityFormProps = {
  opportunity: PurchaseOpportunityDTO;
  entry: PurchaseOpportunityEntryRecordDTO;
  setEntry: (value: PurchaseOpportunityEntryRecordDTO) => void;
  setValidForm: (value: boolean) => void;
  setFormChanged?: (value: boolean) => void;
  newEntry?: boolean;
  isAdmin?: boolean;
};

const PurchaseOpportunityForm: React.FC<PurchaseOpportunityFormProps> = ({
  opportunity,
  entry,
  setEntry,
  setValidForm,
  setFormChanged,
  newEntry = true,
  isAdmin = false,
}) => {
  const { userInfo } = useAuth();
  const [dirty, setDirty] = useState<boolean>(false);
  const [disclosureAccepted, setDisclosureAccepted] = useState<boolean>(false);
  const [validBallparkEmail, setValidBallparkEmail] = useState<boolean>(false);
  const [userBillings, setUserBillings] = useState<BillingDTO[]>([]);
  const [fetchedUserBillings, setFetchedUserBillings] = useState<boolean>(false);

  const onChange = (key: string, value: string | BillingDTO | number[]) => {
    setDirty(true);
    if (!!setFormChanged) {
      setFormChanged(true);
    }
    setEntry({ ...entry, [key]: value });
  };

  const fetchBillings = async (userId: number, setDefault = false) => {
    try {
      const res: BillingDTO[] = await getUserBillings(userId);
      setUserBillings(res);
      if (!entry.userBilling && setDefault) {
        onChange(
          "userBilling",
          res.find((billing: BillingDTO) => billing.isDefault)
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetchedUserBillings(true);
    }
  };

  const onNewCardAdded = (newUserBilling: BillingDTO) => {
    if (!!newUserBilling) {
      setUserBillings([...userBillings, newUserBilling]);
      onChange("userBilling", newUserBilling);
    }
  };

  useEffect(() => {
    if (!newEntry) {
      setDisclosureAccepted(true);
    }
  }, [newEntry]);

  useEffect(() => {
    if (!fetchedUserBillings) {
      if (newEntry) {
        void fetchBillings(userInfo.employeeUserId, newEntry);
      } else if (!!entry?.user) {
        void fetchBillings(entry.user.employeeUserId, newEntry);
      }
    }
  }, [entry, userInfo, newEntry, fetchedUserBillings]);

  useEffect(() => {
    setValidForm(
      dirty &&
        validBallparkEmail &&
        !!entry.purchaseOpportunityGameIds?.length &&
        !!entry.userBilling &&
        disclosureAccepted
    );
  }, [validBallparkEmail, entry, dirty, disclosureAccepted]);

  return (
    <Form>
      <div className="row">
        <FormColumn width={3}>
          <Email
            id="ballparkEmailAddress"
            required
            value={entry.ballparkEmailAddress}
            label="Ballpark Email"
            onChange={(value: string) => onChange("ballparkEmailAddress", value)}
            setValidEmail={setValidBallparkEmail}
            gutterBottom
          />
        </FormColumn>
        <FormColumn width={3}>
          <CreditCardSelect
            id="credit-card-select"
            userBillings={userBillings}
            selected={entry.userBilling}
            setSelected={(value) => onChange("userBilling", value)}
            userId={userInfo.employeeUserId}
            canAddCard={newEntry}
            onNewCardAdded={onNewCardAdded}
            required={true}
            disabled={!newEntry && !isAdmin}
            gutterBottom
          />
        </FormColumn>
      </div>
      <div className="row mb-2">
        <FormColumn width={1}>
          <PurchaseOpportunityGameSelect
            gameNumbers={opportunity.gameNumbers}
            value={entry.purchaseOpportunityGameIds}
            onChange={(value: number[]) => onChange("purchaseOpportunityGameIds", value)}
          />
        </FormColumn>
      </div>
      <hr />
      <div className="row my-2">
        <FormColumn width={1}>
          <HtmlRender html={opportunity.disclosure} />
        </FormColumn>
        <FormColumn width={1} className="mt-2">
          <Checkbox
            id="disclosure"
            label="Accept disclosure"
            onChange={(value: boolean) => setDisclosureAccepted(value)}
            checked={disclosureAccepted}
            disabled={!newEntry}
          />
        </FormColumn>
      </div>
    </Form>
  );
};

export default PurchaseOpportunityForm;
