import { LoginCallback } from "@okta/okta-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteConstants from "./constants/RouteConstants";
import Login from "./containers/Login";

const UnAuthenticatedApp = () => (
  <Routes>
    <Route path={RouteConstants.LOGIN} element={<Login />} />
    <Route path={RouteConstants.LOGIN_CALLBACK} element={<LoginCallback />} />
    <Route path={RouteConstants.WILDCARD} element={<Login />} />
  </Routes>
);

export default UnAuthenticatedApp;
