import { Accordion, CustomMobileAccordionType, Icon, Link, Typography, urlReplace } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OPEN } from "../../../constants/RequestTableConstants";
import RouteConstants from "../../../constants/RouteConstants";
import { GameTime } from "../../../types/Game";
import { getStatusColor, removeRequestId } from "../../../util/TicketRequestUtil";
import { useTimezone } from "../../../util/TimeUtil";
import MatchUp from "../../game/MatchUp";
import ProjectRequestDetails from "./ProjectRequestsDetails";
import { ProjectUserTicketRequestDTO } from "./ProjectRequestsReport";

const Status = styled.div.attrs(() => ({
  className: "px-3 py-2 d-flex justify-content-between",
}))`
  height: 2.5625rem;
  background: ${(props) => props.color};
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
`;

const ProjectRequestsByProjectAccordion = ({
  data: request,
  ...rest
}: CustomMobileAccordionType<ProjectUserTicketRequestDTO>) => {
  const [gameTime, setGameTime] = useState<GameTime>({});

  useEffect(() => {
    setGameTime(useTimezone(request.game.gameDate, request.game.venue.timeZone, request.game.status));
  }, [request]);

  return (
    <>
      <Status color={getStatusColor(request.requestId, request.status.requestStateId)}>
        <div>{request.status.requestStateName}</div>
        {request.status.requestStateId === OPEN ? (
          <Link
            to={{
              pathname: urlReplace(RouteConstants.REQUESTS.EDIT, { requestId: request.requestId }),
            }}
            state={{ previousLocation: { ...request.location, search: removeRequestId(request.location) } }}
          >
            <Icon iconName="fa-pencil-alt" />
          </Link>
        ) : null}
      </Status>
      <Accordion {...rest}>
        <Accordion.Header>
          <div className="d-flex justify-content-between">
            <div>
              <Typography variant="h6">{gameTime.date}</Typography>
              <Typography variant="div">
                {gameTime.day}
                <span className="ms-2">{gameTime.time}</span>
              </Typography>
            </div>
            <MatchUp
              home={request.game.teams.home.team}
              away={request.game.teams.away.team}
              description={request.game.seriesDescription}
            />
          </div>
          <Typography variant="body2">
            {request.quantity} ticket(s) for {request.business}
          </Typography>
        </Accordion.Header>
        <Accordion.Body>
          <ProjectRequestDetails value={request} />
        </Accordion.Body>
      </Accordion>
    </>
  );
};

export default ProjectRequestsByProjectAccordion;
