import React from "react";
import styled from "styled-components";
import { GamePreviewDTO } from "../../types/Game";
import { useTimezone } from "../../util/TimeUtil";
import EventCanceled from "../requests/misc/EventCanceled";
import MatchUp from "./MatchUp";

const GameContainer = styled.div`
  width: 240px;
`;

const Game = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: ${(props) => `solid 1px ${props.theme.colors["grey"]}`};
  padding: 0.75rem;
`;

const GameInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors["charcoal"]};
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

type GamePreviewDisplayProps = {
  game: GamePreviewDTO;
};

const GamePreviewDisplay = ({ game }: GamePreviewDisplayProps) => {
  const tz = game.venue ? game.venue.timeZone : null;
  const { time, day, date } = useTimezone(game.gameDate, tz, game.status);

  return (
    <GameContainer>
      <Game>
        <GameInformation>
          <div>
            <Header>{date}</Header>
            <Description>
              {day}
              <span className="ms-2">{time}</span>
            </Description>
          </div>
        </GameInformation>
        <div>
          <MatchUp home={game.teams.home.team} away={game.teams.away.team} description={game.seriesDescription} />
        </div>
      </Game>
      <EventCanceled game={game} />
    </GameContainer>
  );
};

export default GamePreviewDisplay;
