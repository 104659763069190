import {
  addMonths,
  Card,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  FormColumn,
  getLocalStorageItem,
  getRawDate,
  NotApplicable,
  removeLocalStorageItem,
  Row,
  Select,
  setLocalStorageItem,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAdminSuiteRequests } from "../../../api/RequesTixApi";
import AdminSuiteRequestGame from "../../../components/requests/adminSuite/AdminSuiteRequestGame";
import LocalStorageConstants from "../../../constants/LocalStorageConstants";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { GamePreviewDTO } from "../../../types/Game";
import { AdminSuiteRequestsDTO, SuiteRequestDTO, SuiteRequestStatus } from "../../../types/SuiteRequest";
import { Team } from "../../../types/Team";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { getTeamOption } from "../../../util/TeamUtil";

const today = new Date();
const nextMonth = addMonths(today, 1);
const todayStr = formatDate(today, DATE_FORMATTER_STR);
const nextMonthStr = formatDate(nextMonth, DATE_FORMATTER_STR);

const AdminSuiteRequests = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { teamsOptions, suiteRequestStatusOptions } = useDropdowns();
  const [adminSuiteRequests, setAdminSuiteRequests] = useState<AdminSuiteRequestsDTO>(undefined);

  const localStorageHomeTeamId = getLocalStorageItem(LocalStorageConstants.ADMIN_SUITE_REQUESTS_HOME_TEAM_ID);
  const {
    startDate = todayStr,
    endDate = nextMonthStr,
    homeTeamId = localStorageHomeTeamId,
    statusId,
  } = useRouteParams(location.search);
  const homeTeam: ValueOpt<Team> | undefined = getTeamOption(homeTeamId, teamsOptions);
  const suiteStatus: ValueOpt<SuiteRequestStatus> | undefined = suiteRequestStatusOptions.find(
    (opt: ValueOpt<SuiteRequestStatus>) => opt.value.suiteStatusId === parseInt(statusId)
  );

  const dateRange: DateRange<Date> = {
    start: getRawDate(startDate, DATE_FORMAT_STR),
    end: getRawDate(endDate, DATE_FORMAT_STR),
  };

  const fetchRequests = async (startDate: string, endDate: string, homeTeamId?: number, statusId?: number) => {
    try {
      setLoading(true);
      const res: AdminSuiteRequestsDTO = await fetchAdminSuiteRequests(startDate, endDate, homeTeamId, statusId);
      setAdminSuiteRequests(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateUrl = ({ date: { startDate, endDate }, homeTeamId, statusId }) => {
    if (homeTeamId) {
      setLocalStorageItem(LocalStorageConstants.ADMIN_SUITE_REQUESTS_HOME_TEAM_ID, homeTeamId);
    } else {
      removeLocalStorageItem(LocalStorageConstants.ADMIN_SUITE_REQUESTS_HOME_TEAM_ID);
    }
    updateRouteParams(navigate, RouteConstants.ADMIN.SUITE_REQUESTS.BASE, {
      startDate: startDate,
      endDate: endDate,
      homeTeamId: homeTeamId,
      statusId: statusId,
    });
  };

  const updateValue = useCallback(
    (key: string, value: string | { startDate: string; endDate: string } | number) => {
      updateUrl({
        date: { startDate, endDate },
        homeTeamId,
        statusId,
        [key]: value,
      });
    },
    [startDate, endDate, homeTeamId, statusId]
  );

  const updateDate = (dateRange: DateRange<Date> | NotApplicable) => {
    const dr: DateRange<Date> = dateRange as DateRange<Date>;
    updateValue("date", {
      startDate: formatDate(dr.start, DATE_FORMATTER_STR),
      endDate: formatDate(dr.end, DATE_FORMATTER_STR),
    });
  };

  const refreshData = () => {
    void fetchRequests(startDate, endDate, homeTeamId, statusId);
  };

  useEffect(() => {
    void fetchRequests(startDate, endDate, homeTeamId, statusId);
  }, [startDate, endDate, homeTeamId, statusId]);

  return (
    <Card>
      <Card.Header>Suite Requests</Card.Header>
      <Card.Body>
        <Row className="mb-2">
          <FormColumn width={4}>
            <DateRangeInput
              id="requests-date"
              label="Date"
              value={dateRange}
              onChange={updateDate}
              maxRange={{
                months: 1,
              }}
              withPortal
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="home-team"
              label="Home Team"
              value={homeTeam}
              options={teamsOptions}
              onChange={(value: ValueOpt<Team>) => {
                updateValue("homeTeamId", value?.value?.id);
              }}
              placeholder="select a home team"
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="status"
              label="Status"
              value={suiteStatus}
              options={suiteRequestStatusOptions}
              onChange={(value: ValueOpt<SuiteRequestStatus>) => {
                updateValue("statusId", value?.value?.suiteStatusId);
              }}
              placeholder="Status"
              clearable
              gutterBottom
            />
          </FormColumn>
        </Row>
        {!!adminSuiteRequests && (
          <>
            {adminSuiteRequests.schedule.map((gamePk: number) => {
              const game: GamePreviewDTO = adminSuiteRequests.games[gamePk];
              const requests: SuiteRequestDTO[] = adminSuiteRequests.requests[gamePk];
              return <AdminSuiteRequestGame key={gamePk} game={game} requests={requests} refreshData={refreshData} />;
            })}
          </>
        )}
      </Card.Body>
      <Card.Footer />
    </Card>
  );
};

export default AdminSuiteRequests;
