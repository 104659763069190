import { Accordion, CustomMobileAccordionType, Table } from "best-common-react";
import React from "react";
import styled from "styled-components";
import ToLinkFormatter from "../formatters/ToLinkFormatter";
import { AllUserRequestData } from "./AllUsersRequests";

const Light = styled.div`
  font-weight: 300;
`;

const AllUsersAccordion = ({
  data: {
    recipient,
    department,
    personalComp,
    personalApproved,
    personalCard,
    personalDenied,
    personalTotal,
    businessComp,
    businessApproved,
    businessCard,
    businessDept,
    businessDenied,
    businessTotal,
  },
  ...rest
}: CustomMobileAccordionType<AllUserRequestData>) => {
  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <ToLinkFormatter className="h-100 d-flex align-items-center" value={recipient} />
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <div className="mb-3">
            <b>Department</b>
            <Light>{department.value}</Light>
          </div>

          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header style={{ width: "33%", height: "2rem" }} />
                <Table.Header className="text-center">Personal</Table.Header>
                <Table.Header className="text-center">Business</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.TdInfo>Approved</Table.TdInfo>
                <Table.Td>
                  <ToLinkFormatter value={personalApproved} />
                </Table.Td>
                <Table.Td>
                  <ToLinkFormatter value={businessApproved} />
                </Table.Td>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Comp</Table.TdInfo>
                <Table.Td>
                  <ToLinkFormatter value={personalComp} />
                </Table.Td>
                <Table.Td>
                  <ToLinkFormatter value={businessComp} />
                </Table.Td>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Card</Table.TdInfo>
                <Table.Td>
                  <ToLinkFormatter value={personalCard} />
                </Table.Td>
                <Table.Td>
                  <ToLinkFormatter value={businessCard} />
                </Table.Td>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Dept</Table.TdInfo>
                <Table.Td />
                <Table.Td>
                  <ToLinkFormatter value={businessDept} />
                </Table.Td>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Denied</Table.TdInfo>
                <Table.Td>
                  <ToLinkFormatter value={personalDenied} />
                </Table.Td>
                <Table.Td>
                  <ToLinkFormatter value={businessDenied} />
                </Table.Td>
              </Table.Row>
              <Table.Row>
                <Table.TdInfo>Total</Table.TdInfo>
                <Table.Td>
                  <ToLinkFormatter value={personalTotal} />
                </Table.Td>
                <Table.Td>
                  <ToLinkFormatter value={businessTotal} />
                </Table.Td>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

export default AllUsersAccordion;
