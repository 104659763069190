import { Button, Icon, Input, RequiredIndicator, Table } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { GenericTicket } from "../../types/Tickets";

const IconHolder = styled.div`
  margin-top: 6px;
`;

type TicketsLocationEditingProps = {
  venueId: number;
  tickets: GenericTicket[];
  setTickets: (tickets: GenericTicket[]) => void;
};

const TicketsLocationEditing = ({ venueId, tickets = [], setTickets }: TicketsLocationEditingProps) => {
  const addTicket = () => {
    setTickets([
      ...tickets,
      {
        venueId: venueId,
      },
    ]);
  };

  const removeTicket = (index) => {
    const updated = [...tickets];
    updated.splice(index, 1);
    setTickets(updated);
  };

  const updateTicket = (index, key, value) => {
    const newTickets = [...tickets];
    const newTicket = {
      ...tickets[index],
      [key]: value,
    };
    newTickets[index] = newTicket;
    setTickets(newTickets);
  };

  return (
    <div className="d-flex flex-column">
      <div className="mb-2">
        <Table className="table">
          <Table.Head>
            <Table.Row>
              <Table.Header style={{ width: "50px" }} />
              <Table.Header>
                Section <RequiredIndicator />
              </Table.Header>
              <Table.Header>
                Row <RequiredIndicator />
              </Table.Header>
              <Table.Header>
                Seat <RequiredIndicator />
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {tickets?.map((seasonTicket, index) => (
              <Table.Row key={index}>
                <Table.Td className="text-center">
                  <IconHolder>
                    <Icon iconName="fa-trash-alt" onClick={() => removeTicket(index)} />
                  </IconHolder>
                </Table.Td>
                <Table.Td>
                  <Input
                    id="section"
                    value={seasonTicket.section}
                    onChange={(value: string) => updateTicket(index, "section", value)}
                  />
                </Table.Td>
                <Table.Td>
                  <Input
                    id="row"
                    value={seasonTicket.row}
                    onChange={(value: string) => updateTicket(index, "row", value)}
                  />
                </Table.Td>
                <Table.Td>
                  <Input
                    id="seat"
                    value={seasonTicket.seat}
                    onChange={(value: string) => updateTicket(index, "seat", value)}
                  />
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>

      <Button variant="default" onClick={addTicket} className="align-self-end">
        Add Ticket
      </Button>
    </div>
  );
};

export default TicketsLocationEditing;
