import {
  addDays,
  Button,
  Checkbox,
  DateRange,
  DateRangeInput,
  formatDate,
  FormColumn,
  isBefore,
  Modal,
  Row,
  Select,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";
import { getAdminSchedulePreview } from "../../../api/RequesTixApi";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { GamePreviewDTO } from "../../../types/Game";
import { Team } from "../../../types/Team";
import GameInfo from "../../game/GameInfo";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  border: 1px solid ${(props) => props.theme.colors["grey"]};
`;

const SelectableGamesContainer = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors["grey"]};
  max-height: 20rem;
`;

const SelectableGameRow = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors["grey"]};
  }
`;

type SelectableGameProps = {
  game: GamePreviewDTO;
  selected: boolean;
  onSelect: (gamePk: number) => void;
};

const SelectableGame = ({ game, selected, onSelect }: SelectableGameProps) => (
  <SelectableGameRow>
    <Checkbox
      id={`${game.gamePk}-selection`}
      className="mx-3 pb-2"
      checked={selected}
      onChange={() => onSelect(game.gamePk)}
    />
    <GameInfo
      gameDate={game.gameDate}
      venue={game.venue}
      away={game.teams.away}
      home={game.teams.home}
      seriesDescription={game.seriesDescription}
    />
  </SelectableGameRow>
);

type AdminChangeRequestModalProps = {
  show: boolean;
  game: any;
  onClose: () => void;
  onSave: (gamePk: number) => void;
};

const AdminChangeRequestModal = ({ show, game, onClose, onSave }: AdminChangeRequestModalProps) => {
  const [loading, setLoading] = useState(false);
  const { teamsOptions } = useDropdowns();
  const [selectedGamePk, setSelectedGamePk] = useState<number | undefined>(undefined);
  const [homeTeamId, setHomeTeamId] = useState<number | undefined>(undefined);
  const [games, setGames] = React.useState<GamePreviewDTO[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: undefined,
    end: undefined,
  });

  const fetchGames = async () => {
    try {
      setLoading(true);
      const res: GamePreviewDTO[] = await getAdminSchedulePreview(
        homeTeamId,
        formatDate(dateRange.start!),
        formatDate(dateRange.end!),
        false
      );
      setGames(res.filter((option) => option.gamePk !== game.gamePk));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show && !!homeTeamId && !!dateRange.start && !!dateRange.end && isBefore(dateRange.start, dateRange.end)) {
      void fetchGames();
    }
    return () => {
      setSelectedGamePk(null);
    };
  }, [show, homeTeamId, dateRange.start, dateRange.end]);

  useEffect(() => {
    setDateRange({
      start: addDays(new Date(game.gameDate), -3) as Date,
      end: addDays(new Date(game.gameDate), 3) as Date,
    });
    setHomeTeamId(parseInt(game.teams.home.team.id));
  }, [game]);

  const teamOptVal = useMemo(() => {
    if (!!homeTeamId && !!teamsOptions) {
      return teamsOptions.find((option: ValueOpt<Team>) => parseInt(option.value.id) === homeTeamId);
    } else {
      return undefined;
    }
  }, [homeTeamId, teamsOptions]);

  return (
    <Modal show={show} size="lg">
      <Modal.Header close={onClose}>Change Game</Modal.Header>
      <Modal.Body>
        <Row>
          <FormColumn width={2}>
            <Select
              id="home-team"
              label="Home Team"
              options={teamsOptions}
              value={teamOptVal}
              onChange={(value: ValueOpt<Team>) => setHomeTeamId(parseInt(value.value.id))}
              required
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={2}>
            <DateRangeInput
              id="game-date"
              required
              label="Date"
              value={dateRange}
              onChange={(value: DateRange<Date>) => setDateRange(value)}
              maxRange={{
                weeks: 1,
              }}
              gutterBottom
              withPortal
            />
          </FormColumn>
        </Row>
        <div>
          {!loading ? (
            <SelectableGamesContainer>
              {games.map((selectable) => (
                <SelectableGame
                  key={selectable.gamePk}
                  game={selectable}
                  selected={selectable.gamePk === selectedGamePk}
                  onSelect={setSelectedGamePk}
                />
              ))}
            </SelectableGamesContainer>
          ) : (
            <LoadingContainer>
              <PulseLoader />
            </LoadingContainer>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="me-2" disabled={!selectedGamePk} onClick={() => onSave(selectedGamePk)}>
            Change Game
          </Button>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminChangeRequestModal;
