import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  Button,
  CustomMobileAccordionType,
  Danger,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  DataTableRowThemeType,
  EditTableFormatter,
  Hint,
  Typography,
  urlReplace,
  useLoading,
  useTheme,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import {
  PurchaseOpportunityDTO,
  PurchaseOpportunityEntryDTO,
  PurchaseOpportunityGameDTO,
  PurchaseOpportunityStatusId,
} from "../../types/PurchaseOpportunity";
import { disablePurchaseOpportunityGame } from "../../api/RequesTixApi";

type EntryData = DataTableDataType & {
  user: string;
  purchaseOpportunityEntryId: number;
  status: string;
  purchaseOpportunityId: number;
  purchaseOpportunityStatusId: PurchaseOpportunityStatusId;
  ballparkEmailAddress: string;
  priority: number;
};

const PurchaseOppGameAccordion: React.FC<React.PropsWithChildren<CustomMobileAccordionType<EntryData>>> = ({
  data,
  index,
  ...rest
}) => {
  const navigate = useNavigate();

  const onEditClick = () => {
    navigate(
      urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT, {
        purchaseOpportunityId: data.purchaseOpportunityId,
        purchaseOpportunityEntryId: data.purchaseOpportunityEntryId,
      })
    );
  };

  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <EditTableFormatter value={data.purchaseOpportunityId} row={data} onClick={onEditClick} />
          <span className="mx-2">{data.user}</span>
          {data.status}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Priority</AttributeHeader>
          <AttributeValue>{data.priority}</AttributeValue>
        </div>
        <div>
          <AttributeHeader>Ballpark Email Address</AttributeHeader>
          <AttributeValue>{data.ballparkEmailAddress}</AttributeValue>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

type AdminPurchaseOpportunityGameProps = {
  opp: PurchaseOpportunityDTO;
  game: PurchaseOpportunityGameDTO;
  reload: () => Promise<any>;
};
const AdminPurchaseOpportunityGame: React.FC<AdminPurchaseOpportunityGameProps> = ({ opp, game, reload }) => {
  const navigate = useNavigate();
  const { Theme } = useTheme();
  const { setLoading } = useLoading();
  const [data, setData] = useState<EntryData[]>([]);

  const Columns: DataTableColumn<EntryData>[] = [
    {
      name: "",
      key: "purchaseOpportunityEntryId",
      width: 40,
      onClick: (_rowIndex: number, row: EntryData) => {
        navigate(
          urlReplace(RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT, {
            purchaseOpportunityId: opp.purchaseOpportunityId,
            purchaseOpportunityEntryId: row.purchaseOpportunityEntryId,
          })
        );
      },
      readonlyFormatter: EditTableFormatter,
    },
    {
      key: "user",
      name: "User",
      width: 300,
    },
    {
      key: "status",
      name: "Status",
      width: 150,
    },
    {
      key: "priority",
      name: "Priority",
      width: 50,
    },
    {
      key: "ballparkEmailAddress",
      name: "Ballpark Email Address",
      minWidth: 250,
    },
  ];

  const processEntries = (entries: PurchaseOpportunityEntryDTO[]) => {
    setData(
      entries.map((entry: PurchaseOpportunityEntryDTO) => ({
        id: entry.purchaseOpportunityEntryId,
        user: `${entry.user.firstName} ${entry.user.lastName}`,
        purchaseOpportunityId: opp.purchaseOpportunityId,
        purchaseOpportunityEntryId: entry.purchaseOpportunityEntryId,
        status: entry.purchaseOpportunityStatus.description,
        purchaseOpportunityStatusId: entry.purchaseOpportunityStatus.purchaseOpportunityStatusId,
        ballparkEmailAddress: entry.ballparkEmailAddress,
        priority: entry.priority,
      }))
    );
  };

  const rowStylesGetter = (_index: number, value: EntryData): DataTableRowThemeType | undefined => {
    switch (value.purchaseOpportunityStatusId) {
      case 2:
        return { ...Theme.datatable.row, background: "#f5fc60" };
      case 3:
        return { ...Theme.datatable.row, background: "#fbe3e3" };
      case 4:
        return { ...Theme.datatable.row, background: "#eefbe0" };
      case 5:
        return { ...Theme.datatable.row, background: "#dee8f4" };
      default:
        return Theme.datatable.row;
    }
  };

  const disableGame = async () => {
    try {
      setLoading(true);
      await disablePurchaseOpportunityGame(game.purchaseOpportunityGameId);
      await reload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const selectedCount = useMemo(
    () => data.filter((entry) => [2, 4].includes(entry.purchaseOpportunityStatusId)).length,
    [data]
  );
  const notSelectedCount = useMemo(
    () => data.filter((entry) => 3 === entry.purchaseOpportunityStatusId).length,
    [data]
  );
  const chargedCount = useMemo(() => data.filter((entry) => 4 === entry.purchaseOpportunityStatusId).length, [data]);
  const refundedCount = useMemo(() => data.filter((entry) => 5 === entry.purchaseOpportunityStatusId).length, [data]);

  useEffect(() => {
    processEntries(game.entries);
  }, [game]);

  return (
    <Accordion accordionKey={`game-${game.gameNumber}`} startOpen>
      <Accordion.Header>
        <Typography variant="div">
          <b>Home Game {game.gameNumber}</b> {!!game?.gamePk ? `Game PK - ${game.gamePk}` : ""}
          <Hint className="ms-2">Total Entries: {data.length}</Hint>
          <Hint className="ms-2">Selected Entries: {selectedCount}</Hint>
          <Hint className="ms-2">Not Selected Entries: {notSelectedCount}</Hint>
          <Hint className="ms-2">Charged Entries: {chargedCount}</Hint>
          <Hint className="ms-2">Refunded Entries: {refundedCount}</Hint>
          {!!game.disabled && (
            <Danger
              className="ms-2"
              styles={{
                fontWeight: "bold",
              }}
            >
              Disabled
            </Danger>
          )}
        </Typography>
      </Accordion.Header>
      <Accordion.Body>
        <Button variant="default" disabled={game.disabled} onClick={disableGame}>
          Disable Game
        </Button>
        <DataTable
          columns={Columns}
          data={data}
          statusTextOverride={(_selectedCount: number, totalCount: number) => `${totalCount} entries`}
          rowStylesGetter={rowStylesGetter}
          accordion={PurchaseOppGameAccordion}
        />
      </Accordion.Body>
    </Accordion>
  );
};

export default AdminPurchaseOpportunityGame;
