import { FormColumn, Select, ValueOpt } from "best-common-react";
import React from "react";
import { components, OptionProps } from "react-select";
import types from "../../../../constants/FulfillmentTypeConstants";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { ActionProps } from "./ActionHandler";

const Option = ({ data, ...rest }: OptionProps<ValueOpt<number>>) => {
  const { label } = data;
  return (
    <components.Option {...rest} data={data}>
      <div style={{ minHeight: "24px" }}>{label}</div>
    </components.Option>
  );
};

const FulfillmentTypeSetAction: React.FC<ActionProps> = ({ state, onStateChange, request }) => {
  const { requestFulfillmentTypesOptions } = useDropdowns();
  const [options, setOptions] = React.useState<ValueOpt<number>[]>([]);
  React.useEffect(() => {
    setOptions(
      requestFulfillmentTypesOptions.filter(
        ({ value }) => value === types.COMP || value === request.requestType.requestTypeId || value === types.NONE
      )
    );
  }, [requestFulfillmentTypesOptions, request.requestType]);

  const onFulfillmentTypeChange = ({ value }: ValueOpt<number>) => {
    let newState = { ...state, fulfillmentTypeId: value };
    if (value === types.COMP) {
      newState = { ...newState, chargeQuantity: null, ticketCost: null, orderCost: null };
    }
    onStateChange(newState);
  };

  return (
    <FormColumn width={2}>
      <Select
        id="request-fulfillment-types-dropdown"
        label="Fulfillment Type"
        value={options.find(({ value }: ValueOpt<number>) => value === state.fulfillmentTypeId)}
        options={options}
        onChange={onFulfillmentTypeChange}
        placeholder="select..."
        components={{ Option }}
        gutterBottom
      />
    </FormColumn>
  );
};

export default FulfillmentTypeSetAction;
