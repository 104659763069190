import { Accordion, useTheme } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { AtBatPromotion } from "../../../types/Game";

const HeaderText = styled.span`
  color: ${(props) => props.theme.colors["charcoal"]};
  font-size: 12px;
  font-weight: bold;
`;

const PreviewHolder = styled.div`
  margin-left: 0.25rem;
  color: ${(props) => props.theme.colors["charcoal"]};
  font-size: 0.75rem;
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const PromotionHolder = styled.div.attrs({ className: "col-md-6 col-xl-4" })`
  display: flex;
  margin-top: 0.75rem;
  padding: 0 2rem;
  color: ${(props) => props.theme.colors["charcoal"]};
`;

const PromotionName = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
`;

const PromotionDesc = styled.p`
  margin: 0.25rem 0;
  font-size: 12px;
`;

const PromotionInfo = styled.span`
  font-size: 11px;
  font-weight: bold;
`;

const ImageHolder = styled.div`
  width: 30%;
  margin-right: 0.75rem;
`;

const Promotion = ({ name, imageUrl, description, presentedBy, distribution }: AtBatPromotion) => {
  const info = presentedBy && `${presentedBy} | ` + !distribution ? "All Fans" : distribution;
  return (
    <PromotionHolder>
      <ImageHolder>
        <img className="w-100" src={imageUrl} alt="" />
      </ImageHolder>
      <div style={{ width: "70%" }}>
        <PromotionName>{name}</PromotionName>
        <PromotionDesc>{description}</PromotionDesc>
        <PromotionInfo>{info}</PromotionInfo>
      </div>
    </PromotionHolder>
  );
};

const Promotions = ({ gamePk, promotions }: { gamePk: string; promotions?: null | AtBatPromotion[] }) => {
  const { Theme } = useTheme();
  if (!!promotions) {
    const preview = promotions?.length === 1 ? promotions[0].name : `(${promotions.length})`;
    return (
      <Accordion
        accordionKey={gamePk}
        styles={{
          ...Theme.accordion,
          header: {
            ...Theme.accordion.header,
            background: "#f2f3f4",
            padding: "0.25rem",
          },
          action: {
            ...Theme.accordion.action,
            margin: "0",
            padding: "0 .5rem",
            size: "1rem",
          },
          body: {
            ...Theme.accordion.body,
            background: "#f2f3f4",
          },
        }}
      >
        <Accordion.Header iconLeft={true}>
          <div className="d-flex">
            <HeaderText>{promotions.length === 1 ? "Promotion:" : "Promotions:"}</HeaderText>
            <PreviewHolder>{preview}</PreviewHolder>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row">
            {promotions.map((promotion, index) => (
              <Promotion key={`promotion-${index}`} {...promotion} />
            ))}
          </div>
        </Accordion.Body>
      </Accordion>
    );
  } else {
    return null;
  }
};

export default Promotions;
