import { Button, DATE_FORMATTER_STR, formatDate, FormColumn, Textarea } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { ActionProps } from "./ActionHandler";
import ConfirmationEmailModal from "./ConfirmationEmailModal";

const ToEmail = styled.div`
  margin-left: 1.5rem;
  color: #666666;
  font-size: 0.9rem;
  font-weight: 300;
  font-style: italic;
`;

const SentDate = styled.span`
  margin-left: 0.5rem;
  color: #666666;
  font-size: 0.9rem;
  font-weight: 300;
  font-style: italic;
`;

const ConfirmationEmailSentAction: React.FC<ActionProps> = ({ state, onStateChange, request, statusId, save }) => {
  const [showEditModal, setShowEditModal] = React.useState(false);

  const onClose = () => {
    setShowEditModal(false);
  };
  const textValue = state.confirmationText ? state.confirmationText : "";
  const email = request.notifyRequester ? request.requester.email : request.submitter.email;
  const title = statusId === 3 ? "Request approved" : "Request denied";
  const buttonLabel = !state.confirmationEmailSent ? "Send Confirmation Email" : "Resend Confirmation Email";
  const sentText =
    state && state.confirmationEmailSent
      ? state.confirmationEmailSentCount > 1
        ? "resent " + formatDate(new Date(state.confirmationEmailSentDate), DATE_FORMATTER_STR)
        : "sent " + formatDate(new Date(state.confirmationEmailSentDate), DATE_FORMATTER_STR)
      : "";

  return (
    <>
      <ConfirmationEmailModal
        onClose={onClose}
        isOpen={showEditModal}
        request={request}
        state={state}
        title={title}
        statusId={statusId}
        save={save}
      />
      <FormColumn width={1}>
        <div className="row">
          <FormColumn width={1}>
            <Textarea
              id="confirmationEmailNotes"
              label="Confirmation Email Notes"
              placeholder="add any additional notes to be sent in the confirmation email…"
              disabled={false}
              onChange={(value: string) => {
                onStateChange({ ...state, confirmationText: value });
              }}
              value={textValue}
            />
          </FormColumn>
        </div>
        <div className="row">
          <FormColumn width={1}>
            <div className="d-flex align-items-center my-2">
              <Button
                variant="secondary"
                id="confirmationEmail"
                onClick={() => setShowEditModal(true)}
                disabled={false}
              >
                {buttonLabel}
              </Button>
              <ToEmail>{email}</ToEmail>
              <SentDate>{sentText}</SentDate>
            </div>
          </FormColumn>
        </div>
      </FormColumn>
    </>
  );
};

export default ConfirmationEmailSentAction;
