export const capitalizeFirstLetter = (string: string): string => string.replace(/^\w/, (c) => c.toUpperCase());

export const isNumber = (value?: any): boolean => !!value && typeof value == "number";

export const toLower = (str: any): string => {
  if (str) {
    if (isNumber(str)) {
      return str.toString();
    } else {
      return str.toString().toLowerCase();
    }
  } else {
    return "";
  }
};
