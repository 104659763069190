import { useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { getUsersRequests } from "../../../api/RequesTixApi";
import GameQueryConstants from "../../../constants/GameQueryConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { UserTicketRequestDTO } from "../../../types/UserRequest";
import { requestsFreeTextSearch } from "../../../util/TicketRequestUtil";
import MyRequestsTable from "../../requests/personal/MyRequestsTable";

const UserRequests = ({
  categoryId,
  seasonId,
  fulfillmentTypeId,
  gameSearchId = GameQueryConstants.PAST_ID,
  statusId,
  userId,
  search,
  location,
  setCanExport,
}) => {
  const { userInfo, isAdmin } = useAuth();
  const { setLoading } = useLoading();
  const [requests, setRequests] = useState([]);
  const [displayRequests, setDisplayRequests] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data: UserTicketRequestDTO[] = await getUsersRequests(
        isAdmin ? userId : userInfo.employeeUserId,
        null,
        gameSearchId,
        categoryId,
        statusId || "",
        seasonId,
        fulfillmentTypeId
      );
      setRequests(data);
      setCanExport(!!data.length);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && userId && seasonId) {
      void fetchData();
    }
  }, [categoryId, seasonId, fulfillmentTypeId, statusId, userId, gameSearchId, userInfo]);

  useEffect(() => {
    setDisplayRequests(requestsFreeTextSearch(requests, search));
  }, [requests, search]);

  return <MyRequestsTable requests={displayRequests} size={requests.length} location={location} />;
};

export default UserRequests;
