import { datadogRum } from "@datadog/browser-rum";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { getLocalStorageItem, useAlerts } from "best-common-react";
import React, { useContext, useEffect, useState } from "react";
import AlertConstants from "../constants/AlertConstants";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import { UserInfo } from "../types/User";
import { getUserInfo } from "../util/UserUtil";
import { useEnv } from "./EnvContext";

type AuthContextType = {
  oktaAuth: OktaAuth;
  initialized: boolean;
  loggedIn: boolean;
  userInfo: UserInfo;
  isProd: boolean;
  isAdmin: boolean;
  isCoordinator: boolean;
  onLogin: () => Promise<void>;
  logout: () => void;
  permissionError: boolean;
  setPermissionError: (value: boolean) => void;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children, isProd, oktaAuth }) => {
  const {
    variables: { OKTA_ISSUER },
  } = useEnv();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [isCoordinator, setIsCoordinator] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const { addAlert } = useAlerts();

  const onLogin = async (): Promise<void> => {
    try {
      const userInfo = await getUserInfo();
      if (userInfo) {
        checkForAdmin(userInfo);
        setUserInfo(userInfo);
        setLoggedIn(true);
      } else {
        addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "Could not find user for your account",
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setInitialized(true);
    }
  };

  const logout = (): void => {
    localStorage.clear();
    setLoggedIn(false);
    setUserInfo({} as UserInfo);
  };

  const checkOktaIssuer = () => {
    try {
      const oktaToken = getLocalStorageItem<any>(LocalStorageConstants.OKTA_TOKEN);
      if (!!oktaToken?.idToken) {
        if (OKTA_ISSUER !== oktaToken.idToken?.issuer) {
          logout();
        }
      } else {
        logout();
      }
    } catch (e) {
      console.error(e);
      logout();
    }
  };

  const checkForAdmin = (data: UserInfo): void => {
    if (data.roles) {
      if (data.roles.includes("ROLE_ADMIN")) {
        setIsAdmin(true);
      }
      if (data.roles.includes("ROLE_COORDINATOR")) {
        setIsCoordinator(true);
      }
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated !== undefined) {
      if (authState?.isAuthenticated) {
        // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
        onLogin();
      } else {
        setInitialized(true);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (!!userInfo) {
      datadogRum.setUser({
        id: userInfo.employeeUserId?.toString(),
        email: userInfo.email,
        name: `${userInfo.lastName}, ${userInfo.firstName}`,
      });
    } else {
      datadogRum.removeUser();
    }
  }, [userInfo]);

  useEffect(() => {
    checkOktaIssuer();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        initialized,
        oktaAuth,
        loggedIn,
        userInfo,
        isProd,
        isAdmin,
        isCoordinator,
        onLogin,
        logout,
        permissionError,
        setPermissionError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context: AuthContextType | undefined = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
