import { DataTableDataType, FormatterType } from "best-common-react";
import React from "react";
import styled from "styled-components";
import TeamLogo from "../game/TeamLogo";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TeamLogoFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value } = props;
  return (
    <Container>
      <TeamLogo teamId={value} />
    </Container>
  );
};

export default TeamLogoFormatter;
