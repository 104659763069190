import { BCR, BCRSuspense, Traditional } from "best-common-react";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";
import PermissionDenied from "./AccessDenied";
import AuthenticatedApp from "./AuthenticatedApp";
import { useAuth } from "./contexts/AuthContext";
import UnAuthenticatedApp from "./UnAuthenticatedApp";

const AppCheck: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedIn, permissionError, initialized } = useAuth();
  const [hasRedirected, setHasRedirected] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>("");

  useEffect(() => {
    if (!pathname.length) {
      setPathname(location.pathname + location.search);
    }
    if (loggedIn && !hasRedirected && !!pathname.length) {
      navigate(pathname);
      setHasRedirected(true);
    }
  }, [loggedIn, location.pathname]);

  if (initialized) {
    return <>{permissionError ? <PermissionDenied /> : loggedIn ? <AuthenticatedApp /> : <UnAuthenticatedApp />}</>;
  } else {
    return <UnAuthenticatedApp />;
  }
};

const App: React.FC = () => {
  return (
    <BCR theme={Traditional}>
      <React.Suspense fallback={<BCRSuspense />}>
        <Router>
          <AppCheck />
        </Router>
      </React.Suspense>
    </BCR>
  );
};

export default App;
