import qs from "query-string";
import { NavigateFunction } from "react-router/dist/lib/hooks";

export const useRouteParams = (search: string): any => {
  let params = {};

  if (search) {
    params = qs.parse(search);
  }

  return params;
};

export const createRouteSearch = (params: object): string => {
  let search = "?";

  let i = 0;
  for (const key in params) {
    const val = params[key];
    if (val) {
      search += `${i !== 0 ? "&" : ""}${key}=${encodeURI(val)}`;
      i++;
    }
  }

  return search;
};

export const updateRouteParams = (navigate: NavigateFunction, pathname: string, params: object, state?: any) => {
  const search = createRouteSearch(params);
  navigate(pathname + search, {
    state: state,
  });
};

export const urlReplace = (str: string, data: object): string => {
  if (str) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      str = str.replace(`:${key}`, data[key]);
    });
    return str;
  } else {
    return "";
  }
};
