import {
  Button,
  Card,
  FormColumn,
  Row,
  Search,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getDepartmentsBySeason, updateDepartmentsBySeason } from "../../../api/RequesTixApi";
import BulkEditDepartmentModal from "../../../components/department/BulkEditDepartmentModal";
import DepartmentTable from "../../../components/department/DepartmentTable";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useMetadata } from "../../../contexts/MetadataContext";
import { DepartmentBySeasonDTO } from "../../../types/Department";
import { Season } from "../../../types/Season";
import { convertToEventTypeDTO } from "../../../util/CutoffUtil";

const AdminDepartments = () => {
  const { loading, setLoadingNonBlocking } = useLoading();
  const { seasonsOptions } = useDropdowns();
  const { activeSeason } = useMetadata();
  const [departments, setDepartments] = useState<DepartmentBySeasonDTO[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<DepartmentBySeasonDTO[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<Season>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const getDepartmentData = async (seasonId: number) => {
    try {
      setLoadingNonBlocking(true);
      const res: DepartmentBySeasonDTO[] = await getDepartmentsBySeason(seasonId);
      setDepartments(res);
      setSelectedDepartments([]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  const onSave = async ({ personalAllotment, businessAllotment, access, cutoff, personalRequests }) => {
    try {
      setLoadingNonBlocking(true);
      const values = {
        personalAllotment: personalAllotment.amount || personalAllotment.unlimited ? personalAllotment : null,
        businessAllotment: businessAllotment.amount || businessAllotment.unlimited ? businessAllotment : null,
        access: access.active ? access : null,
        cutoff: cutoff.active ? convertToEventTypeDTO(cutoff) : null,
        personalRequests: personalRequests,
      };
      const departmentIds: number[] = selectedDepartments.map((d: DepartmentBySeasonDTO) => d.departmentId);
      const res: DepartmentBySeasonDTO[] = await updateDepartmentsBySeason(departmentIds, selectedSeason, values);
      setSelectedDepartments([]);
      setDepartments(res);
      setShowEditModal(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  useEffect(() => {
    setSelectedSeason(activeSeason);
  }, [activeSeason]);

  useEffect(() => {
    if (selectedSeason?.seasonId) {
      getDepartmentData(selectedSeason?.seasonId);
    }
  }, [selectedSeason]);

  return (
    <Card>
      <Card.Header>Departments</Card.Header>
      <Card.Body>
        {showEditModal && (
          <BulkEditDepartmentModal
            show={showEditModal}
            selected={selectedDepartments}
            season={`${selectedSeason.year} ${selectedSeason.seasonType.seasonTypeName}`}
            onClose={() => setShowEditModal(false)}
            onSave={onSave}
          />
        )}

        <Row>
          <FormColumn width={4}>
            <Select
              id="seasons"
              label="Season"
              options={seasonsOptions}
              value={seasonsOptions.find(
                (option: ValueOpt<Season>) => option.value.seasonId === selectedSeason?.seasonId
              )}
              onChange={(value) => setSelectedSeason(value?.value)}
              gutterBottom
            />
          </FormColumn>
          <FormColumn width={4}>
            <Search
              id="search-box"
              label="Search Departments"
              placeholder="begin typing to filter departments"
              value={searchTerm}
              onChange={setSearchTerm}
              gutterBottom
            />
          </FormColumn>
        </Row>
        <DepartmentTable
          values={departments}
          searchTerm={searchTerm}
          selectedSeason={selectedSeason}
          selected={selectedDepartments}
          setSelected={setSelectedDepartments}
          loading={loading}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" disabled={selectedDepartments.length === 0} onClick={() => setShowEditModal(true)}>
            Update Departments
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminDepartments;
