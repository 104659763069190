import { Button, StickyFooterButtons } from "best-common-react";
import React from "react";
import CancelConfirmation from "../misc/CancelConfirmation";

type AdminFooterProps = {
  onSave: () => void;
  onCancel: () => void;
  canSave?: boolean;
  hasEdited: boolean;
};

const AdminFooter = ({ onSave, onCancel, canSave = true, hasEdited }: AdminFooterProps) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const onCancelClick = () => {
    hasEdited ? setShowModal(true) : onCancel();
  };
  return (
    <>
      <CancelConfirmation show={showModal} onCancel={onCancel} onClose={() => setShowModal(false)} />
      <StickyFooterButtons startsOpen={false}>
        <Button variant="primary" disabled={!hasEdited || !canSave} onClick={onSave}>
          Save
        </Button>
        <Button variant="default" onClick={onCancelClick}>
          Cancel
        </Button>
      </StickyFooterButtons>
    </>
  );
};

export default AdminFooter;
