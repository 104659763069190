import { DesktopTabletView, DesktopView, MobileView, Row, Typography } from "best-common-react";
import React from "react";
import styled from "styled-components";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { EventTimingDTO, EventTimingTypeDTO } from "../../../types/EventTiming";
import { AtBatPromotion, GameRequestCountDTO, GameStatus } from "../../../types/Game";
import { Teams } from "../../../types/Team";
import { Venue } from "../../../types/Venue";
import { useTimezone } from "../../../util/TimeUtil";
import MatchUp from "../../game/MatchUp";
import Promotions from "./Promotions";
import TicketRequestSection from "./TicketRequestSection";
import { useLocation, useNavigate } from "react-router-dom";

type GameCardProps = {
  isPromotion: boolean;
};

const GameCard = styled.div<GameCardProps>`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  ${(props) => props.isPromotion && "border-bottom: 0;"}
  background-color: #fff;
  margin: 0.5rem 0 0 0;
  padding: 0.75rem 1rem;
`;

const InfoContainer = styled.div.attrs({ className: "col-md-6 col-lg-7" })`
  display: flex;
  justify-content: space-between;
  padding-left: 0;
`;

const LeftSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 30%;
  }
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors["charcoal"]};
`;

const DescHeader = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

type GameProps = {
  gamePk: string;
  gameDate: Date;
  venue: Venue;
  description: string;
  teams: Teams;
  access: EventTimingTypeDTO;
  cutoff: EventTimingDTO;
  personalRequests: boolean;
  blackedOut: boolean;
  requestCount: GameRequestCountDTO;
  status: GameStatus;
  promotions: AtBatPromotion[];
};

const Game = ({
  gamePk,
  gameDate,
  venue,
  teams,
  access,
  cutoff,
  promotions = [],
  personalRequests,
  requestCount,
  description,
  blackedOut,
  status,
}: GameProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { time, day, date } = useTimezone(gameDate, venue.timeZone, status);

  const toRequest = (type: string) => {
    navigate(urlReplace(RouteConstants.REQUESTS.TICKETS, { gamePk, type }), {
      state: { previousLocation: location },
    });
  };

  return (
    <div>
      <GameCard isPromotion={!!promotions}>
        <Row>
          <InfoContainer>
            <LeftSection>
              <div>
                <Header>{date}</Header>
                <Description>
                  <Typography>{day}</Typography>
                  <MobileView>
                    <Typography className="ms-2">{time}</Typography>
                  </MobileView>
                </Description>
              </div>
              <MatchUp home={teams.home.team} away={teams.away.team} description={description} />
            </LeftSection>
            <DesktopTabletView>
              <Description>{time}</Description>
            </DesktopTabletView>
            <DesktopView>
              <div style={{ width: "25%" }}>
                <DescHeader>{venue.name}</DescHeader>
                {venue.location ? (
                  <Description>
                    {venue.location.city}
                    {venue.location.city && venue.location.stateAbbrev ? ", " : ""}
                    {venue.location.stateAbbrev}
                  </Description>
                ) : null}
              </div>
            </DesktopView>
          </InfoContainer>
          <TicketRequestSection
            gameDate={gameDate}
            access={access}
            cutoff={cutoff}
            toRequest={toRequest}
            personalAllowed={personalRequests}
            requestCount={requestCount}
            gamePk={gamePk}
            blackedOut={blackedOut}
          />
        </Row>
      </GameCard>
      <Promotions gamePk={gamePk} promotions={promotions} />
    </div>
  );
};

export default Game;
