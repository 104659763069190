import { Table } from "best-common-react";
import React from "react";
const RequestAllotmentTableMobile = ({ allotment, category }) => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header colSpan={2} style={{ textAlign: "center" }}>
            {category}
          </Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.TdInfo>Pending</Table.TdInfo>
          <Table.Td>{allotment.pending}</Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>Approved</Table.TdInfo>
          <Table.Td>
            {allotment.used} {allotment.comped ? ` (${allotment.comped} comped)` : ""}
          </Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>Allotted</Table.TdInfo>
          <Table.Td>{allotment.unlimited ? "unlimited" : allotment.allowed}</Table.Td>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default RequestAllotmentTableMobile;
