import { Table } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUserAllotmentCounts } from "../../api/RequesTixApi";
import UserAllotmentConstants from "../../constants/UserAllotmentConstants";

const SubCategory = styled.span.attrs(() => ({
  className: "ms-2",
}))`
  font-weight: normal;
`;

const sumCounts = (values) => {
  const comped = values.reduce((acc, value) => acc + value.comped, 0);
  const card = values.reduce((acc, value) => acc + value.card, 0);
  const dept = values.reduce((acc, value) => acc + value.dept, 0);
  const accepted = values.reduce((acc, value) => acc + value.accepted, 0);
  const denied = values.reduce((acc, value) => acc + value.denied, 0);
  const total = values.reduce((acc, value) => acc + value.total, 0);
  return {
    comped,
    card,
    denied,
    dept,
    accepted,
    total,
  };
};

type UserAllotmentCount = {
  comped: number;
  card: number;
  denied: number;
  dept: number;
  accepted: number;
  total: number;
};

type UserAllotmentPersonalBusinessCounts = {
  personal?: UserAllotmentCount;
  business?: UserAllotmentCount;
};

const UserAllotmentBreakdownDisplay = ({ userId, year }) => {
  const [allotment, setAllotment] = useState<UserAllotmentPersonalBusinessCounts>({
    personal: undefined,
    business: undefined,
  });

  useEffect(() => {
    if (userId && year) {
      getUserAllotmentCounts(userId, year).then((data) => {
        if (data) {
          const personal = data.filter(
            (allotment) => allotment.allotmentType.allotmentTypeId === UserAllotmentConstants.PERSONAL_ID
          );
          const business = data.filter(
            (allotment) => allotment.allotmentType.allotmentTypeId === UserAllotmentConstants.BUSINESS_ID
          );
          const allotments = {
            personal: sumCounts(personal),
            business: sumCounts(business),
          };
          setAllotment(allotments);
        }
      });
    }
  }, [userId, year]);

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header style={{ width: "30%" }} />
          <Table.Header className="text-center">Personal</Table.Header>
          <Table.Header className="text-center">Business</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.TdInfo>Approved</Table.TdInfo>
          <Table.Td className="text-center">{allotment.personal?.accepted}</Table.Td>
          <Table.Td className="text-center">{allotment.business?.accepted}</Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>
            <SubCategory>Comped</SubCategory>
          </Table.TdInfo>
          <Table.Td className="text-center">{allotment.personal?.comped}</Table.Td>
          <Table.Td className="text-center">{allotment.business?.comped}</Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>
            <SubCategory>Card</SubCategory>
          </Table.TdInfo>
          <Table.Td className="text-center">{allotment.personal?.card}</Table.Td>
          <Table.Td className="text-center">{allotment.business?.card}</Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>
            <SubCategory>Dept</SubCategory>
          </Table.TdInfo>
          <Table.Td className="text-center">{allotment.personal?.dept}</Table.Td>
          <Table.Td className="text-center">{allotment.business?.dept}</Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>Denied</Table.TdInfo>
          <Table.Td className="text-center">{allotment.personal?.denied}</Table.Td>
          <Table.Td className="text-center">{allotment.business?.denied}</Table.Td>
        </Table.Row>
        <Table.Row>
          <Table.TdInfo>Total</Table.TdInfo>
          <Table.Td className="text-center">{allotment.personal?.total}</Table.Td>
          <Table.Td className="text-center">{allotment.business?.total}</Table.Td>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default UserAllotmentBreakdownDisplay;
