import { Button, Card, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, saveSuiteRequest } from "../../../api/RequesTixApi";
import SuiteRequestForm from "../../../components/requests/suite/SuiteRequestForm";
import RouteConstants from "../../../constants/RouteConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { SuiteRequestSaveDTO } from "../../../types/SuiteRequest";
import { UserDTO } from "../../../types/User";

const NewSuiteRequest = () => {
  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();
  const { userInfo } = useAuth();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserDTO | undefined>(undefined);
  const [suiteRequest, setSuiteRequest] = useState<SuiteRequestSaveDTO>({});

  const getCurrentUser = async () => {
    try {
      setLoading(true);
      const data: UserDTO = await getUser(userInfo.employeeUserId);
      setCurrentUser(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const save = async () => {
    try {
      setLoading(true);
      await saveSuiteRequest(suiteRequest);
      navigate(RouteConstants.REQUESTS.SUITES);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = () => {
    setSuiteRequest({});
    navigate(-1);
  };

  useEffect(() => {
    if (!!currentUser) {
      setSuiteRequest({
        isMlbPaying: false,
        requesterUser: currentUser,
        onBehalfOfUser: currentUser,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    void getCurrentUser();
  }, []);

  return (
    <Card>
      <Card.Header>Suite Requests</Card.Header>
      <Card.Body>
        {!loading && (
          <SuiteRequestForm
            suiteRequest={suiteRequest}
            setSuiteRequest={setSuiteRequest}
            setValidForm={setCanSave}
            currentUser={currentUser}
          />
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="primary" onClick={save} disabled={!canSave}>
            Submit
          </Button>
          <Button variant="default" onClick={cancel}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default NewSuiteRequest;
