import {
  DataTable,
  DataTableColumn,
  DateRange,
  DateRangeInput,
  DateTimeFormatter,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  FormColumn,
  getRawDate,
  Row,
  Select,
  sort,
  useLoading,
  ValueOpt,
  IconFormatter,
  urlReplace,
  isBefore,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getCloseCutoffRequests, getDepartments } from "../../../api/RequesTixApi";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { Department } from "../../../types/Department";
import { ReconciliationTicketRequestDTO } from "../../../types/UserRequest";
import { previousMonth, yesterday } from "../../../util/DateObjUtil";
import { All, getCategoryOption, getDepartmentOption } from "../../../util/DropdownUtil";
import GameDateFormatter from "../../tables/GameDateFormatter";
import { ReportHandlerTypeProps } from "../ReportHandler";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../../constants/RouteConstants";

const TimeBeforeCutoffFormatter = ({ value }) => {
  const getTimeStr = (value: number): string => {
    const days: number = Math.floor(value / 24);
    const hours: number = Math.floor(value % 24);
    if (days > 0 && hours > 0) {
      return `${days} ${days > 1 ? "Days" : "Day"}, ${hours} ${hours > 1 ? "Hours" : "Hour"}`;
    } else if (days > 0) {
      return `${days} ${days > 1 ? "Days" : "Day"}`;
    } else {
      return `${hours} ${hours > 1 ? "Hours" : "Hours"}`;
    }
  };

  return <div>{getTimeStr(value)}</div>;
};

const CloseCutoffReport = ({ reportData, setReportData }: ReportHandlerTypeProps) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { startDate, endDate, departmentId, categoryId } = reportData;
  const { requestCategoryOptions } = useDropdowns();
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: !!startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
    end: !!endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
  });
  const [requests, setRequests] = useState<ReconciliationTicketRequestDTO[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<ValueOpt<Department>[]>([]);

  const Columns: DataTableColumn<ReconciliationTicketRequestDTO>[] = [
    {
      name: "",
      key: "requestId",
      width: 30,
      readonlyFormatter: IconFormatter,
      icon: "fa-pencil",
      onClick: (_index: number, value: ReconciliationTicketRequestDTO) => {
        navigate(
          urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, {
            requestId: value.requestId,
          })
        );
      },
    },
    {
      name: "Game PK",
      key: "gamePk",
      width: 100,
    },
    {
      name: "Game",
      key: "game",
      readonlyFormatter: GameDateFormatter,
      minWidth: 325,
    },
    {
      name: "Time Before Cutoff",
      key: "hoursBetweenRequestAndCutoff",
      readonlyFormatter: TimeBeforeCutoffFormatter,
      width: 200,
    },
    {
      name: "Requested By",
      key: "requester",
      width: 200,
    },
    {
      name: "Submitted Time",
      key: "submitted",
      readonlyFormatter: DateTimeFormatter,
      width: 225,
    },
    {
      name: "Submitted By",
      key: "submitter",
      width: 200,
    },
    {
      name: "Number of Tickets",
      key: "quantity",
      width: 150,
    },
  ];

  const getData = async (startDate: string, endDate: string, departmentId?: number, categoryId?: number) => {
    try {
      setLoading(true);
      const result: ReconciliationTicketRequestDTO[] = await getCloseCutoffRequests(
        startDate,
        endDate,
        departmentId,
        categoryId
      );
      setRequests(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  const updateDate = ({ start, end }: DateRange<Date>) => {
    if (!!start && !!end) {
      setReportData({
        ...reportData,
        startDate: formatDate(start, DATE_FORMATTER_STR),
        endDate: formatDate(end, DATE_FORMATTER_STR),
      });
    }
  };

  useEffect(() => {
    const newDate: DateRange<Date> = {
      start: startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
      end: endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
    };
    setDateRange(newDate);
    const startDateStr = formatDate(newDate.start, DATE_FORMATTER_STR);
    const endDateStr = formatDate(newDate.end, DATE_FORMATTER_STR);
    setReportData({ ...reportData, startDate: startDateStr, endDate: endDateStr });
    if (isBefore(newDate.start, newDate.end)) {
      void getData(startDateStr, endDateStr, departmentId, categoryId);
    }
  }, [startDate, endDate, departmentId, categoryId]);

  useEffect(() => {
    getDepartments().then((data) => {
      setDepartmentOptions(
        [All].concat(
          sort(
            data.map((d) => ({
              label: d.departmentName,
              value: d,
            })),
            "label",
            1
          )
        )
      );
    });
  }, []);

  return (
    <div>
      <Row>
        <FormColumn width={4}>
          <DateRangeInput
            id="game-date-range"
            label="Game Date Range"
            required
            gutterBottom
            value={dateRange}
            onChange={updateDate}
            withPortal
          />
        </FormColumn>
        <FormColumn width={4}>
          <Select
            id="department"
            label="Department"
            options={departmentOptions}
            value={getDepartmentOption(departmentId, departmentOptions)}
            onChange={(value: ValueOpt<Department>) => {
              onChange("departmentId", value.value?.departmentId);
            }}
          />
        </FormColumn>
        <FormColumn width={4}>
          <Select
            id="category"
            label="Category"
            options={requestCategoryOptions}
            value={getCategoryOption(categoryId, requestCategoryOptions)}
            onChange={(value) => {
              onChange("categoryId", value.value.requestCategoryId);
            }}
          />
        </FormColumn>
      </Row>
      <DataTable className="mt-2" data={requests} columns={Columns} />
    </div>
  );
};

export default CloseCutoffReport;
