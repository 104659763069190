import { CheckboxGroup, DraggableItem, Icon, Typography, ValueOpt } from "best-common-react";
import React, { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const List = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  width: 150px;
  height: 40px;
  border: 1px solid #d2d2d2;
  color: #4c8cee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

const ItemLabel = styled.div`
  width: 150px;
  height: 40px;
  font-weight: bold;
`;

type PurchaseOpportunityGameSelect = {
  gameNumbers: number[];
  value: number[];
  onChange: (value: number[]) => void;
};

type GameNumber = 1 | 2 | 3 | 4;

const getOrdinal = (i: number): string => {
  switch (i) {
    case 4:
      return "4th";
    case 3:
      return "3rd";
    case 2:
      return "2nd";
    case 1:
    default:
      return "1st";
  }
};

const PurchaseOpportunityGameSelect: React.FC<PurchaseOpportunityGameSelect> = ({ gameNumbers, value, onChange }) => {
  const [gameOpts, setGameOpts] = useState<ValueOpt<GameNumber>[]>([]);
  const [localOptions, setLocalOptions] = useState<ValueOpt<GameNumber>[]>([]);
  const [labelOpts, setLabelOpts] = useState<ValueOpt<string>[]>([]);

  const onDrag = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = localOptions[dragIndex];
      const hoverItem = localOptions[hoverIndex];
      setLocalOptions((values: ValueOpt<GameNumber>[]) => {
        const updatedValues = [...values];
        updatedValues[dragIndex] = hoverItem;
        updatedValues[hoverIndex] = dragItem;
        return updatedValues;
      });
    },
    [localOptions]
  );

  const onDrop = () => {
    onChange(localOptions.map((opt: ValueOpt<GameNumber>) => opt.value));
  };

  const createGameOpts = (gameNumbers: number[]) => {
    const newOpts: ValueOpt<GameNumber>[] = gameNumbers.map((i: number) => {
      return {
        value: i as GameNumber,
        label: `Home Game #${i}`,
      };
    });
    setGameOpts(newOpts);
    const newLabelOpts: ValueOpt<string>[] = [];
    for (let i = 1; i < gameNumbers.length + 1; i++) {
      newLabelOpts.push({
        value: i.toString(),
        label: `${getOrdinal(i)} Preference`,
      });
    }
    setLabelOpts(newLabelOpts);
  };

  useEffect(() => {
    setLocalOptions(
      value.map((v: number) => ({
        value: v as GameNumber,
        label: `Home Game #${v}`,
      }))
    );
  }, [value]);

  useEffect(() => {
    createGameOpts(gameNumbers);
  }, [gameNumbers]);

  return (
    <Container>
      <CheckboxGroup
        id="games-list"
        label="Home Games Wanted"
        required
        onChange={(values: GameNumber[]) => {
          onChange(values.map((v: GameNumber) => v));
        }}
        options={gameOpts}
        values={value}
        gutterBottom
      />
      <div className="mt-2">
        <DndProvider backend={HTML5Backend}>
          <Typography>
            Please order the Home Games in terms of your preference, first being highest, last being lowest
          </Typography>
          <div className="d-flex">
            <List>
              {labelOpts.map((value: ValueOpt<string>) => (
                <ItemLabel key={value.value}>{value.label}</ItemLabel>
              ))}
            </List>
            <List>
              {localOptions.map((opt: ValueOpt<GameNumber>, index: number) => (
                <DraggableItem
                  groupName="game-options-ordered"
                  canDrag={true}
                  key={opt.value}
                  index={index}
                  onDrag={onDrag}
                  onDrop={onDrop}
                >
                  <Item>
                    <Icon iconName="fa-grip" className="me-2" />
                    <div>{opt.label}</div>
                  </Item>
                </DraggableItem>
              ))}
            </List>
          </div>
        </DndProvider>
      </div>
    </Container>
  );
};

export default PurchaseOpportunityGameSelect;
