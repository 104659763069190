import * as H from "history";
import RouteConstants, { urlReplace } from "../../../../constants/RouteConstants";
import { AdminRequestSeriesDTO, RequestAggregationDTO } from "../../../../types/AdminRequest";
import { RequestixLocationState } from "../../../../types/Routing";
import { AggregationData, BySeriesData, GameData, TotalData } from "./BySeriesTypes";

const massageGameData = (
  data: RequestAggregationDTO,
  location: H.Location<RequestixLocationState>,
  subrow: boolean
): GameData => {
  const toLink = subrow
    ? urlReplace(RouteConstants.ADMIN.REQUESTS_GAME, { gamePk: data.gamePreviewDTO.gamePk })
    : urlReplace(RouteConstants.ADMIN.REQUESTS_SERIES, {
        season: new Date(data.gamePreviewDTO.gameDate).getFullYear(),
        homeTeamId: data.gamePreviewDTO.teams.home.team.id,
        seriesNumber: data.gamePreviewDTO.seriesNumber,
        gameType: data.gamePreviewDTO.gameType,
      });
  return {
    location: location,
    toLink: toLink,
    gamePk: data.gamePreviewDTO.gamePk,
    gameDate: data.gamePreviewDTO.gameDate,
    rescheduledFrom: data.gamePreviewDTO.rescheduledFrom,
    venue: data.gamePreviewDTO.venue,
    homeTeam: data.gamePreviewDTO.teams.home.team,
    awayTeam: data.gamePreviewDTO.teams.away.team,
    status: data.gamePreviewDTO.status,
    seriesDescription: data.gamePreviewDTO.seriesDescription,
  };
};

const massageAggregationData = (data: RequestAggregationDTO): AggregationData => ({
  total: {
    value: data.personal.total + data.business.total,
    filter: "",
  },
  personalOpen: {
    value: data.personal.open,
    filter: "categoryId=1&statusId=1",
  },
  personalInReview: {
    incomplete: data.personal.inReviewIncomplete,
    complete: data.personal.inReviewComplete,
    filter: "categoryId=1&statusId=2",
  },
  personalApproved: {
    incomplete: data.personal.approvedIncomplete,
    complete: data.personal.approvedComplete,
    filter: "categoryId=1&statusId=3",
  },
  personalDenied: {
    incomplete: data.personal.deniedIncomplete,
    complete: data.personal.deniedComplete,
    filter: "categoryId=1&statusId=4",
  },
  personalTotal: {
    value: data.personal.total,
    filter: "categoryId=1",
  },
  businessOpen: {
    value: data.business.open,
    filter: "categoryId=2&statusId=1",
  },
  businessInReview: {
    incomplete: data.business.inReviewIncomplete,
    complete: data.business.inReviewComplete,
    filter: "categoryId=2&statusId=2",
  },
  businessApproved: {
    incomplete: data.business.approvedIncomplete,
    complete: data.business.approvedComplete,
    filter: "categoryId=2&statusId=3",
  },
  businessDenied: {
    incomplete: data.business.deniedIncomplete,
    complete: data.business.deniedComplete,
    filter: "categoryId=2&statusId=4",
  },
  businessTotal: {
    value: data.business.total,
    filter: "categoryId=2",
  },
});

const massageSeries = (series: RequestAggregationDTO[], location: H.Location<RequestixLocationState>): TotalData[] => {
  return series.map((game: RequestAggregationDTO) => {
    const aggregationData: AggregationData = massageAggregationData(game);
    const gameData: GameData = massageGameData(game, location, true);
    return {
      ...gameData,
      ...aggregationData,
    };
  });
};

export const massageBySeriesData = (
  data: AdminRequestSeriesDTO[],
  location: H.Location<RequestixLocationState>
): BySeriesData[] => {
  return data.map((d: AdminRequestSeriesDTO) => {
    const aggregationData: AggregationData = massageAggregationData(d);
    const gameData: GameData = massageGameData(d, location, false);
    const seriesData: TotalData[] = massageSeries(d.series, location);
    return {
      ...gameData,
      ...aggregationData,
      subRows: seriesData,
    };
  });
};
