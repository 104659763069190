import {
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  FormColumn,
  getRawDate,
  isBefore,
  Row,
  Select,
  sort,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { getDayOfRequests, getDepartments } from "../../../api/RequesTixApi";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import TodaysTicketsDataTable, { DayOfTableData } from "../../../containers/admin/todaysTickets/TodaysTicketsDataTable";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { Department } from "../../../types/Department";
import { Team } from "../../../types/Team";
import { DayOfGameRequestDTO } from "../../../types/TodaysTickets";
import { previousMonth, yesterday } from "../../../util/DateObjUtil";
import { getDepartmentOption } from "../../../util/DropdownUtil";
import { formatSubmittedDate } from "../../../util/TimeUtil";
import { ReportHandlerTypeProps } from "../ReportHandler";

const TodaysTicketsReport = ({ reportData, setReportData, setCanExport }: ReportHandlerTypeProps) => {
  const { setLoading } = useLoading();
  const { teamsOptions } = useDropdowns();
  const { startDate, endDate, homeTeamId, departmentId } = reportData;
  const [dateRange, setDateRange] = useState<DateRange<Date>>({
    start: !!startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
    end: !!endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
  });
  const [displayTeamOptions, setDisplayTeamOptions] = useState<ValueOpt<Team>[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [requests, setRequests] = useState<DayOfTableData[]>([]);
  const canExport = useMemo(
    () => !!homeTeamId && !!dateRange.start && !!dateRange.end && isBefore(dateRange.start, dateRange.end),
    [homeTeamId, dateRange]
  );

  const onChange = (key: string, value: any) => {
    setReportData({
      ...reportData,
      [key]: value,
    });
  };

  const updateDate = ({ start, end }: DateRange<Date>) => {
    if (!!start && !!end) {
      setReportData({
        ...reportData,
        startDate: formatDate(start, DATE_FORMATTER_STR),
        endDate: formatDate(end, DATE_FORMATTER_STR),
      });
    }
  };

  const getData = async (
    teamId: number | undefined,
    startDate: Date,
    endDate: Date,
    departmentId: number | undefined
  ) => {
    try {
      setLoading(true);
      if (canExport) {
        const res: DayOfGameRequestDTO[] = await getDayOfRequests(
          formatDate(startDate),
          formatDate(endDate),
          teamId,
          departmentId
        );
        const data: DayOfTableData[] = res.map((req: DayOfGameRequestDTO) => ({
          dayOfRequestId: req.dayOfRequestId,
          editLink: urlReplace(RouteConstants.ADMIN.TODAYS_TICKETS_EDIT, { requestId: req.dayOfRequestId }),
          firstName: req.user.firstName,
          lastName: req.user.lastName,
          ballparkEmail: req.emailAddress,
          numberOfTickets: req.numberOfTickets,
          game: req.game,
          ticketsSentDisplay: req.ticketSentDisplay,
          status: req.status,
          requestTime: formatSubmittedDate(req.requestedDate),
          statusId: req.statusId,
        }));
        setRequests(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newDate: DateRange<Date> = {
      start: startDate ? getRawDate(startDate, DATE_FORMAT_STR) : previousMonth,
      end: endDate ? getRawDate(endDate, DATE_FORMAT_STR) : yesterday,
    };
    if (!startDate || !endDate) {
      updateDate(newDate);
    }
    setDateRange(newDate);
    void getData(homeTeamId, newDate.start, newDate.end, departmentId);
  }, [homeTeamId, startDate, endDate, departmentId, canExport]);

  useEffect(() => {
    setDisplayTeamOptions(teamsOptions.filter((team) => team.label !== "All"));
  }, [teamsOptions]);

  useEffect(() => {
    getDepartments().then((data: Department[]) => {
      setDepartmentOptions(
        [
          {
            label: "All",
            value: {},
          },
        ].concat(
          sort(
            data.map((d: Department) => ({
              label: d.departmentName,
              value: d,
            })),
            "label",
            1
          )
        )
      );
    });
  }, []);

  useEffect(() => {
    setCanExport(canExport);
  }, [canExport]);

  return (
    <>
      <Row>
        <FormColumn width={4}>
          <div className="form-group">
            <Select
              id="home-team-dropdown"
              label="Home Team"
              options={displayTeamOptions}
              value={displayTeamOptions.find((opt: ValueOpt<Team>) => {
                if (!!homeTeamId) {
                  return opt.value.id === homeTeamId.toString();
                } else {
                  return !opt.value.id;
                }
              })}
              onChange={(team: ValueOpt<Team>) => onChange("homeTeamId", team.value.id || null)}
              gutterBottom
            />
          </div>
        </FormColumn>
        <FormColumn width={4}>
          <DateRangeInput
            id="game-date"
            label="Game Date"
            value={dateRange}
            onChange={updateDate}
            maxRange={{
              months: 3,
            }}
            withPortal
            gutterBottom
          />
        </FormColumn>
        <FormColumn width={4}>
          <Select
            id="department"
            label="Department"
            options={departmentOptions}
            value={getDepartmentOption(departmentId, departmentOptions)}
            onChange={(value) => {
              onChange("departmentId", value.value.departmentId);
            }}
            gutterBottom
          />
        </FormColumn>
      </Row>
      <TodaysTicketsDataTable requests={requests} />
    </>
  );
};

export default TodaysTicketsReport;
