import { Card, getLocalStorageItem, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getDayOfAllotment, getDayOfGames, getIsLottoActive } from "../api/RequesTixApi";
import EnvFile from "../assets/env.json";
import TodaysTicketsGame from "../components/todaysTickets/TodaysTicketsGame";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import { useSse } from "../hooks/useSse";
import { DayOfGameDTO, TodaysTicketsUpdate } from "../types/TodaysTickets";
import { isPastHourOfDay } from "../util/TimeUtil";
import { AuthToken } from "../types/Auth";

const { SSE_URL, ENV } = EnvFile;

const Container = styled.div`
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
`;

const TodaysTicketsContainer: React.FC = () => {
  const { setLoading } = useLoading();
  const [games, setGames] = useState<DayOfGameDTO[]>([]);
  const [allotment, setAllotment] = useState<number>(4);
  const [isPastAccessTime, setIsPastAccessTime] = useState<boolean>(false);
  const { accessToken } = getLocalStorageItem<AuthToken>(LocalStorageConstants.OKTA_TOKEN);
  const [isLotto, setIsLotto] = useState<boolean>(false);
  const [data] = useSse<TodaysTicketsUpdate>({
    config: {
      sseUrl: `${SSE_URL}/sse/register`,
      channel: `requestix-${ENV}.todays-tickets`,
      accessToken: accessToken.accessToken,
    },
  });

  const getDayOfGamesData = async () => {
    try {
      setLoading(true);
      const result: DayOfGameDTO[] = await getDayOfGames();
      setGames(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getDayOfAllotmentCall = async () => {
    try {
      const result: number = await getDayOfAllotment();
      setAllotment(result);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const replaceTicketData = (ticketData: TodaysTicketsUpdate) => {
    setGames(
      games.map((game: DayOfGameDTO) => {
        if (ticketData.gamePk === game.gamePk) {
          return { ...game, ticketsLeft: ticketData.ticketCount };
        } else {
          return game;
        }
      })
    );
  };

  useEffect(() => {
    if (isPastAccessTime) {
      void getDayOfGamesData();
      void getDayOfAllotmentCall();
    }
  }, [isPastAccessTime]);

  useEffect(() => {
    if (!!data) {
      replaceTicketData(data);
    }
  }, [data]);

  useEffect(() => {
    setIsPastAccessTime(isPastHourOfDay(7));
    getIsLottoActive().then((result) => setIsLotto(result));
  }, []);

  return (
    <Card>
      <Card.Header>Today's tickets</Card.Header>
      <Card.Body>
        <Container>
          {isPastAccessTime ? (
            <>
              {games.length ? (
                <>
                  {games.map((game: DayOfGameDTO) => (
                    <TodaysTicketsGame
                      key={game.gamePk}
                      game={game}
                      refreshData={() => getDayOfGamesData()}
                      allotment={allotment}
                      isLotto={game.isLotto}
                    />
                  ))}
                </>
              ) : (
                <div>There are no tickets available to any games today, please check back tomorrow</div>
              )}
            </>
          ) : (
            <div>Today's Tickets become available at 7 AM EST, please check back after that time.</div>
          )}
          {!!isLotto && (
            <div>
              ATTENTION: In an effort to increase the number of people who can access Today's Tickets, users will have
              until 10am (in each game's respective time zone) to submit ticket requests. At which point, requests for
              each game will be entered into a lottery, and tickets will be distributed to those selected. After the
              drawing, any remaining tickets will be available here to be claimed on a first come, first serve basis.
            </div>
          )}
        </Container>
      </Card.Body>
    </Card>
  );
};

export default TodaysTicketsContainer;
