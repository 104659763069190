import { Icon } from "best-common-react";
import React from "react";

type AdminRequestCompleteIconProps = {
  className?: string;
  complete: boolean;
};

const AdminRequestCompleteIcon = ({ complete, className }: AdminRequestCompleteIconProps) => (
  <Icon
    iconName="fa-check-circle"
    className={className}
    styles={{
      color: !!complete ? "rgb(0, 166, 81)" : "rgb(210, 210, 210)",
      hoverColor: !!complete ? "rgb(0, 166, 81)" : "rgb(210, 210, 210)",
    }}
  />
);

export default AdminRequestCompleteIcon;
