import { Button, Modal, useLoading } from "best-common-react";
import React from "react";
import { sendConfirmationEmail } from "../../../../api/RequesTixApi";
import EmailTypesConstants from "../../../../constants/EmailTypesConstants";
import { ActionStateDTO } from "../../../../types/Action";
import { FlattenedTicketRequest } from "../../../../types/TicketRequest";

type ConfirmationEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  request: FlattenedTicketRequest;
  state: ActionStateDTO;
  title: string;
  statusId: number;
  save: (value?: boolean) => Promise<any>;
};

const ConfirmationEmailModal: React.FC<ConfirmationEmailModalProps> = ({
  isOpen,
  onClose,
  request,
  state,
  title,
  statusId,
  save,
}) => {
  const { setLoading } = useLoading();

  const handleResponse = (): void => {
    setLoading(false);
    onClose();
    void save();
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await save(false);
      if (statusId === 3) {
        sendConfirmationEmail(state, EmailTypesConstants.REQUEST_CONFIRMATION_APPROVED).then(handleResponse);
      } else {
        sendConfirmationEmail(state, EmailTypesConstants.REQUEST_CONFIRMATION_DENIED).then(handleResponse);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const email: string = request.notifyRequester ? request.requester.email : request.submitter.email;
  const name: string = request.notifyRequester
    ? request.requester.firstName + " " + request.requester.lastName
    : request.submitter.firstName + " " + request.submitter.lastName;

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header close={onClose}>{title}</Modal.Header>
      <Modal.Body>
        <div>Please confirm you wish to send a confirmation email to</div>
        <div>
          <b>
            {name} - {email}
          </b>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={false} onClick={sendEmail}>
          Send
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationEmailModal;
