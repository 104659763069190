import { Checkbox, Input, randomString } from "best-common-react";
import React, { useState } from "react";
import styled from "styled-components";
import { RequestTypeDesc } from "../../types/RequestType";

const AllotmentInput = styled.div`
  margin-right: 1rem;
  width: 3rem;
`;

const EditableTableData = styled.td`
  width: 35%;
  &&& {
    vertical-align: middle;
  }
`;

type EditableAllotmentProps = {
  type: RequestTypeDesc;
  value?: number;
  unlimited?: boolean;
  onValueChange: (type: RequestTypeDesc, value: number) => void;
  onUnlimitedChange: (type: RequestTypeDesc, value: boolean) => void;
  title?: React.ReactNode;
};

const EditableAllotment = ({
  type,
  value,
  unlimited,
  onValueChange,
  onUnlimitedChange,
  title,
}: EditableAllotmentProps) => {
  const [id] = useState<string>(randomString());

  return (
    <EditableTableData>
      <div className="d-flex align-items-center justify-content-between justify-content-sm-start">
        <div className="d-flex align-items-center">{!!title && <div className="me-2">{title}</div>}</div>
        <div className="d-flex align-items-center">
          {!unlimited && (
            <AllotmentInput>
              <Input
                id={`allotment-input-${id}`}
                type="number"
                disabled={unlimited}
                value={value}
                onChange={(val) => {
                  onValueChange(type, val);
                }}
              />
            </AllotmentInput>
          )}
          <Checkbox
            id={`allotment-checkbox-${id}`}
            label="unlimited"
            checked={unlimited}
            onChange={() => {
              onUnlimitedChange(type, !unlimited);
            }}
          />
        </div>
      </div>
    </EditableTableData>
  );
};

export default EditableAllotment;
