import { Select, ValueOpt } from "best-common-react";
import React from "react";

const TicketRequestNotificationsSelect = ({ submitter, requester, notifyRequester, onChange }) => {
  const [options, setOptions] = React.useState<ValueOpt<boolean>[]>([]);

  React.useEffect(() => {
    setOptions([
      {
        label: `${submitter.lastName}, ${submitter.firstName}`,
        value: false,
      },
      {
        label: `${requester.lastName}, ${requester.firstName}`,
        value: true,
      },
    ]);
  }, [submitter, requester]);

  return (
    <Select
      id="notification-user-select"
      label="Send Email Notifications to"
      required
      gutterBottom
      value={options.find((option) => option.value === notifyRequester)}
      options={options}
      onChange={({ value }: ValueOpt<boolean>) => onChange("notifyRequester", value)}
    />
  );
};

export default TicketRequestNotificationsSelect;
