import {
  Button,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  FormatterType,
  Icon,
  SortFilters,
  tableSort,
} from "best-common-react";
import React from "react";
import styled from "styled-components";
import { DepartmentByGameDTO } from "../../types/Department";
import { EventTimingDTO, EventTimingTypeDTO } from "../../types/EventTiming";
import { GameDTO } from "../../types/Game";
import { useCutoffTime } from "../../util/TimeUtil";
import EventAccessAccordion from "./EventAccessAccordion";

const StrikeThrough = styled.span`
  text-decoration: line-through;
  color: ${(props) => props.theme.colors["charcoal"]};
  margin-left: 1rem;
`;

const AddOverride = styled(Button)`
  width: 100%;
  padding: 3px 0;
`;

const OverrideFormatter = ({
  value: { isOverride, department, onAdd, onEdit, onDelete },
}: FormatterType<EventAccessData>) =>
  !isOverride ? (
    <div className="d-flex justify-content-center">
      <AddOverride onClick={() => onAdd(department)}>Add</AddOverride>
    </div>
  ) : (
    <div className="d-flex justify-content-around">
      <Icon iconName="fa-pencil-alt" className="fa-lg me-3" onClick={() => onEdit(department)} />
      <Icon iconName="fa-trash-alt" className="fa-lg" onClick={() => onDelete(department)} />
    </div>
  );

const AccessCutoffFormatter = ({ value: { time, override } }: FormatterType<EventAccessData>) =>
  !override ? (
    <div>{time}</div>
  ) : (
    <div>
      {override}
      {"     "}
      <StrikeThrough>{time}</StrikeThrough>
    </div>
  );

const PersonalRequestFormatter = ({ value: { enable, override } }: FormatterType<EventAccessData>) =>
  override === null ? (
    <div>{enable ? "On" : "Off"}</div>
  ) : (
    <div>
      {override ? "On" : "Off"}
      {"     "}
      <StrikeThrough>{enable ? "On" : "Off"}</StrikeThrough>
    </div>
  );

type EventAccessTableProps = {
  event: GameDTO;
  departments: DepartmentByGameDTO[];
  onOverrideEdit: (department: DepartmentByGameDTO) => void;
  onOverrideAdd: (department: DepartmentByGameDTO) => void;
  onOverrideDelete: (department: DepartmentByGameDTO) => void;
};

export type EventAccessData = DataTableDataType & {
  override: {
    isOverride: EventTimingDTO | EventTimingTypeDTO | boolean;
    department: DepartmentByGameDTO;
    onAdd: (department: DepartmentByGameDTO) => void;
    onEdit: (department: DepartmentByGameDTO) => void;
    onDelete: (department: DepartmentByGameDTO) => void;
  };
  department: string;
  access: {
    time: string | null;
    override: string | null;
  };
  personalCutoff: {
    time: string | null;
    override: string | null;
  };
  businessCutoff: {
    time: string | null;
    override: string | null;
  };
  personalRequests: {
    enable: boolean;
    override: boolean;
  };
};

const EventAccessTable = ({
  event,
  departments = [],
  onOverrideAdd,
  onOverrideEdit,
  onOverrideDelete,
}: EventAccessTableProps) => {
  const [data, setData] = React.useState<EventAccessData[]>([]);
  const [displayData, setDisplayData] = React.useState<EventAccessData[]>([]);
  const [sortFilters, setSortFilters] = React.useState<SortFilters<EventAccessData>>({
    key: "department",
    direction: "ASC",
  });
  const cols: DataTableColumn<EventAccessData>[] = [
    { key: "override", name: "Override", readonlyFormatter: OverrideFormatter, width: 100 },
    { key: "department", name: "Department", width: 250, sortable: true },
    { key: "access", name: "Event Access (local time)", readonlyFormatter: AccessCutoffFormatter, minWidth: 250 },
    {
      key: "personalCutoff",
      name: "Personal Event Cutoff (local time)",
      readonlyFormatter: AccessCutoffFormatter,
      minWidth: 250,
    },
    {
      key: "businessCutoff",
      name: "Business Event Cutoff (local time)",
      readonlyFormatter: AccessCutoffFormatter,
      minWidth: 250,
    },
    { key: "personalRequests", name: "Personal Requests", readonlyFormatter: PersonalRequestFormatter, minWidth: 250 },
  ];

  React.useEffect(() => {
    const newData: EventAccessData[] = departments.map((department) => {
      const {
        departmentAccess,
        accessOverride,
        departmentCutoff,
        cutoffOverride,
        personalRequests,
        personalRequestsOverride,
      } = department;
      return {
        override: {
          isOverride: accessOverride || cutoffOverride || personalRequestsOverride != null,
          department: department,
          onAdd: onOverrideAdd,
          onEdit: onOverrideEdit,
          onDelete: onOverrideDelete,
        },
        department: department.departmentName,
        access: {
          time: departmentAccess
            ? useCutoffTime(event, departmentAccess.useDate, departmentAccess.date, departmentAccess.hoursBefore)
            : "",
          override: accessOverride
            ? useCutoffTime(event, accessOverride.useDate, accessOverride.date, accessOverride.hoursBefore)
            : null,
        },
        personalCutoff: {
          time: departmentCutoff
            ? useCutoffTime(
                event,
                departmentCutoff.personal.useDate,
                departmentCutoff.personal.date,
                departmentCutoff.personal.hoursBefore
              )
            : "",
          override: cutoffOverride
            ? useCutoffTime(
                event,
                cutoffOverride.personal.useDate,
                cutoffOverride.personal.date,
                cutoffOverride.personal.hoursBefore
              )
            : null,
        },
        businessCutoff: {
          time: departmentCutoff
            ? useCutoffTime(
                event,
                departmentCutoff.business.useDate,
                departmentCutoff.business.date,
                departmentCutoff.business.hoursBefore
              )
            : "",
          override: cutoffOverride
            ? useCutoffTime(
                event,
                cutoffOverride.business.useDate,
                cutoffOverride.business.date,
                cutoffOverride.business.hoursBefore
              )
            : null,
        },
        personalRequests: {
          enable: personalRequests,
          override: personalRequestsOverride,
        },
      };
    });
    setData(newData);
  }, [departments]);

  React.useEffect(() => {
    setDisplayData(tableSort(data, sortFilters));
  }, [data, sortFilters]);

  return (
    <DataTable
      data={displayData}
      columns={cols}
      sortFunction={(key, direction) => setSortFilters({ key, direction })}
      accordion={EventAccessAccordion}
      sortColumn={sortFilters["key"]}
      sortDirection={sortFilters["direction"]}
    />
  );
};

export default EventAccessTable;
