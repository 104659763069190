import React from "react";
import styled from "styled-components";
import { Offset } from "../../../util/TimeUtil";

type ContainerProps = {
  isPersonal: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 7.8125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => (props.isPersonal ? props.theme.colors["primary"] : "#00549d")};
`;

const TimeHolder = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.125rem 0;
  margin-bottom: 0.25rem;
`;

const TimeValueHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
`;

const LightText = styled.div`
  text-align: center;
  font-size: 0.625rem;
  font-weight: 300;
  line-height: 0.75rem;
`;

const TimeValue = ({ unit, children }) => (
  <TimeValueHolder>
    <div>{children}</div>
    <LightText>{unit}</LightText>
  </TimeValueHolder>
);

type TimeRemainingProps = {
  offset?: Offset;
  isPersonal: boolean;
};

const TimeRemaining = ({
  offset = {
    days: 0,
    hours: 0,
    minutes: 0,
    text: "",
  },
  isPersonal,
}: TimeRemainingProps) => (
  <Container isPersonal={isPersonal}>
    <TimeHolder>
      <TimeValue unit="days">{offset?.days}</TimeValue>
      <TimeValue unit="hrs">{offset?.hours}</TimeValue>
      <TimeValue unit="mins">{offset?.minutes}</TimeValue>
    </TimeHolder>
    <LightText>{offset?.text}</LightText>
  </Container>
);

export default TimeRemaining;
