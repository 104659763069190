import { getLocalStorageItem, useAlerts, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { Alert, AlertConstants, AlertTypes } from "../constants/AlertConstants";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import CyberSourceClient from "./CyberSourceClient";
import RequestixClient from "./RequestixClient";
import { AuthToken } from "../types/Auth";

const getErrorMessage = (error) => {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 500:
        return "Internal Error with Requestix";
      case 404:
        return "Requestix Resource Not Found";
      case 400:
        return "Bad request to Requestix";
      default:
        break;
    }
  }
  return error.message;
};

const removeOldInterceptors = (type, id) => {
  if (!!id) {
    type.eject(id);
  }
};

const HttpInterceptor = ({ children }) => {
  const [responseIds, setResponseIds] = useState({});
  const { addAlert } = useAlerts();
  const { setLoading } = useLoading();

  const createResponseAlerts = (alerts: Alert[]) => {
    alerts.forEach((alert: Alert) => {
      addAlert({
        type: !!alert.severity ? (alert.severity.toLowerCase() as AlertTypes) : AlertConstants.TYPES.WARNING,
        text: !!alert.message ? alert.message : "There was an error please contact support",
      });
    });
  };

  const handleCyberSource = (error) => {
    const { responseStatus } = error.response.data;
    let message;
    if (responseStatus.details && responseStatus.details.length > 0) {
      message = responseStatus.details[0].message;
    } else {
      switch (responseStatus.status) {
        case 400:
          message = "Invalid Credit Card Details";
          break;
        default:
          message = "Credit Card Error";
          break;
      }
    }
    addAlert({
      type: AlertConstants.TYPES.DANGER,
      text: message,
    });
  };

  useEffect(() => {
    //@ts-ignore
    removeOldInterceptors(RequestixClient.interceptors.request, responseIds.requestixRequestId);
    //@ts-ignore
    removeOldInterceptors(RequestixClient.interceptors.response, responseIds.requestixResponseId);
    //@ts-ignore
    removeOldInterceptors(CyberSourceClient.interceptors.request, responseIds.csRequestId);
    //@ts-ignore
    removeOldInterceptors(CyberSourceClient.interceptors.response, responseIds.csReponseId);

    const requestixRequestId = RequestixClient.interceptors.request.use(
      (config) => {
        if (getLocalStorageItem(LocalStorageConstants.OKTA_TOKEN)) {
          const { accessToken } = getLocalStorageItem<AuthToken>(LocalStorageConstants.OKTA_TOKEN);
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
          }
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    const requestixResponseId = RequestixClient.interceptors.response.use(
      (response) => {
        if (response.data) {
          if (response.data.alerts) {
            createResponseAlerts(response.data.alerts);
          }
        }
        return response;
      },
      (error) => {
        if (error) {
          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              //todo: not sure what to do here now
            } else if (error.response && error.response.data && error.response.data.alerts) {
              createResponseAlerts(error.response.data.alerts);
            }
          } else if (error.message) {
            const errorMessage = getErrorMessage(error);
            addAlert({
              type: AlertConstants.TYPES.DANGER,
              text: errorMessage,
            });
          } else {
            addAlert({
              type: AlertConstants.TYPES.DANGER,
              text: "There was an error with Requestix please try again or contact support",
            });
          }
        } else {
          addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: "There was an error with Requestix please try again or contact support",
          });
        }
        setLoading(false);
        return Promise.reject(error);
      }
    );

    const csRequestId = CyberSourceClient.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    const csReponseId = CyberSourceClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error) {
          handleCyberSource(error);
        }
        setLoading(false);
        return Promise.reject(error);
      }
    );

    setResponseIds({
      csReponseId,
      csRequestId,
      requestixRequestId,
      requestixResponseId,
    });
  }, []);

  return <>{children}</>;
};

export default HttpInterceptor;
