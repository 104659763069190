import {
  Button,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  DateFormatter,
  FormatterType,
  FormColumn,
  getSortDir,
  IconAdorner,
  includes,
  Link,
  PriceFormatter,
  Row,
  SortDirection,
  SortFilters,
  sortWithFunction,
  Table,
  toLower,
  toMoney,
  urlReplace,
  useLoading,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getReceiptPDF, getReconciliationRequests } from "../../../api/RequesTixApi";
import RouteConstants from "../../../constants/RouteConstants";
import { ReconciliationTicketRequestDTO } from "../../../types/UserRequest";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import GameDateFormatter from "../../tables/GameDateFormatter";
import { ReportHandlerTypeProps } from "../ReportHandler";

const RequestFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value } = props;
  const url = urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: value });
  return <Link to={url}>{value}</Link>;
};

const Columns: DataTableColumn<ReconciliationTicketRequestDTO>[] = [
  {
    name: "Request",
    key: "requestId",
    minWidth: 100,
    sortable: true,
    readonlyFormatter: RequestFormatter,
  },
  {
    name: "Charge Date",
    key: "chargeDate",
    minWidth: 150,
    sortable: true,
    readonlyFormatter: DateFormatter,
  },
  {
    name: "Total Ticket Cost",
    key: "totalTicketCost",
    minWidth: 150,
    sortable: true,
    cellClass: "text-center",
    readonlyFormatter: PriceFormatter,
  },
  {
    name: "Department",
    key: "department",
    minWidth: 200,
    sortable: true,
  },
  {
    name: "Requester",
    key: "requester",
    minWidth: 300,
    sortable: true,
  },
  {
    name: "# of Tickets",
    key: "quantity",
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Business",
    key: "business",
    minWidth: 200,
    sortable: true,
  },
  {
    name: "Game",
    key: "game",
    minWidth: 350,
    readonlyFormatter: GameDateFormatter,
    sortable: true,
  },
  {
    name: "Order Charged",
    key: "orderCost",
    minWidth: 125,
    sortable: true,
    readonlyFormatter: PriceFormatter,
  },
  {
    name: "Admin comments",
    key: "adminComments",
    minWidth: 400,
    sortable: true,
  },
];

const freeTextFilter = (data: ReconciliationTicketRequestDTO[], search: string): ReconciliationTicketRequestDTO[] =>
  !!search?.length
    ? data.filter(
        (data: ReconciliationTicketRequestDTO) =>
          includes(data.business?.toString(), search) ||
          includes(data.requestId?.toString(), search) ||
          includes(data.gamePk?.toString(), search) ||
          includes(data.department, search) ||
          includes(data.quantity?.toString(), search) ||
          includes(data.totalTicketCost?.toString(), search) ||
          includes(data.requester, search) ||
          includes(data.orderCost?.toString(), search)
      )
    : data;

const ReconciliationDataTable = ({ reportData }: ReportHandlerTypeProps) => {
  const { startDate, endDate, fulfillmentTypeId, homeTeamId } = reportData;
  const { setLoading } = useLoading();
  const [data, setData] = useState<ReconciliationTicketRequestDTO[]>([]);
  const [displayData, setDisplayData] = useState<ReconciliationTicketRequestDTO[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<ReconciliationTicketRequestDTO>>({
    key: "chargeDate",
    direction: "ASC",
  });
  const [totalTickets, setTotalTickets] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [selectedRequestsIds, setSelectedRequestIds] = useState<number[]>([]);
  const onSort = (key: keyof ReconciliationTicketRequestDTO, direction: SortDirection) =>
    setSortFilters({ key, direction });

  const canExportReceipt = (rowIndex: number): boolean => {
    const value: ReconciliationTicketRequestDTO = displayData[rowIndex];
    return value?.fulfillmentType === "charge card";
  };

  const fetchData = async (startDate: string, endDate: string, fulfillmentTypeId?: number, homeTeamId?: number) => {
    try {
      setLoading(true);
      const results: ReconciliationTicketRequestDTO[] = await getReconciliationRequests(
        startDate,
        endDate,
        fulfillmentTypeId,
        homeTeamId
      );
      setData(
        results.map((result) => ({
          ...result,
          chargeDate: !!result.chargeDate ? new Date(result.chargeDate) : null,
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onSelectionChange = (rows: number[], selected: boolean) => {
    if (selected) {
      setSelectedRequestIds([...selectedRequestsIds, ...rows.map((row) => data[row].requestId)]);
    } else {
      setSelectedRequestIds(
        selectedRequestsIds.filter((requestId) => !rows.includes(data.findIndex((row) => row.requestId === requestId)))
      );
    }
  };

  const isSelected = (rowIndex: number) => {
    const value: ReconciliationTicketRequestDTO = displayData[rowIndex];
    return selectedRequestsIds.includes(value?.requestId);
  };

  const downloadReceipt = async (requestId: number) => {
    try {
      const response = await getReceiptPDF(requestId);
      downloadLink(response, FileTypes.PDF);
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  };

  const downloadReceipts = async () => {
    try {
      setLoading(true);
      await Promise.all(selectedRequestsIds.map((requestId) => downloadReceipt(requestId)));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!startDate && !!endDate) {
      void fetchData(startDate, endDate, fulfillmentTypeId, homeTeamId);
    }
  }, [startDate, endDate, fulfillmentTypeId, homeTeamId]);

  useEffect(() => {
    if (data) {
      setDisplayData(
        sortWithFunction(
          freeTextFilter(data, reportData.search),
          sortFilters.key,
          getSortDir(sortFilters.direction),
          (value) => {
            if (sortFilters.key === "game") {
              return value.gameDate;
            } else {
              return value ? toLower(value) : "";
            }
          }
        )
      );
    } else {
      setDisplayData([]);
    }
  }, [data, sortFilters, reportData.search]);

  useEffect(() => {
    let tickets = 0;
    let cost = 0;

    data.forEach((ticket) => {
      tickets += ticket.quantity;
      cost += ticket.totalTicketCost;
    });

    setTotalTickets(tickets);
    setTotalCost(cost);
  }, [data]);

  return (
    <>
      <Row className="mt-2">
        <FormColumn width={3}>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header>Ticket Qty</Table.Header>
                <Table.Header>Cost</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Td>{totalTickets}</Table.Td>
                <Table.Td>{toMoney(totalCost)}</Table.Td>
              </Table.Row>
            </Table.Body>
          </Table>
        </FormColumn>
      </Row>
      <DataTable
        columns={Columns}
        data={displayData}
        sortColumn={sortFilters.key}
        sortDirection={sortFilters.direction}
        sortFunction={onSort}
        rowSelection={{
          isSelected,
          isSelectionDisabled: (rowIndex: number) => !canExportReceipt(rowIndex),
          onSelectionChange,
        }}
        statusTextOverride={(_selected: number, total: number) => `${total} Request`}
      />
      <Button
        variant="default"
        className="mt-2"
        onClick={downloadReceipts}
        disabled={!selectedRequestsIds.length}
        startAdorner={<IconAdorner iconName="fa-download" />}
      >
        Receipt
      </Button>
    </>
  );
};

export default ReconciliationDataTable;
