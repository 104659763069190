import { Select, ValueOpt } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { getBusinesses } from "../../../api/RequesTixApi";
import { Business } from "../../../types/Business";
import AddBusinessModal from "./AddBusinessModal";

const AddNewBusiness = styled.span`
  margin-left: 1.5rem;
  color: rgb(76, 140, 238);

  &:hover {
    cursor: pointer;
  }
`;

type TicketRequestBusinessSelectProps = {
  businessId?: number;
  setBusiness?: (value?: Business) => void;
  required?: boolean;
};

const BusinessSelect: React.FC<TicketRequestBusinessSelectProps> = ({ businessId, setBusiness, required = false }) => {
  const [options, setOptions] = React.useState([]);
  const [selectedBusiness, setSelectedBusiness] = React.useState<ValueOpt<Business> | undefined>();
  const [showModal, setShowModal] = React.useState(false);
  React.useEffect(() => {
    // Will run initially and when the modal is closed
    if (!showModal) {
      getBusinesses().then((res) =>
        setOptions(
          res.map((business) => ({
            label: business.businessName,
            value: business,
          }))
        )
      );
    }
  }, [showModal]);

  React.useEffect(() => {
    setSelectedBusiness(options.find((option) => option.value.businessId === businessId));
  }, [businessId, options]);

  return (
    <>
      <AddBusinessModal onClose={() => setShowModal(false)} isOpen={showModal} setSelectedBusiness={setBusiness} />
      <Select
        id="add-business"
        required={required}
        label={
          <div className="d-flex">
            <div>Business</div>
            <AddNewBusiness onClick={() => setShowModal(true)}>Add a business</AddNewBusiness>
          </div>
        }
        placeholder="select a business"
        options={options}
        value={selectedBusiness}
        onChange={(value: ValueOpt<Business> | undefined) => setBusiness(value?.value)}
        clearable={true}
        gutterBottom
      />
    </>
  );
};

export default BusinessSelect;
