import { Button, Card, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSuiteRequest, getUser, saveSuiteRequest } from "../../../api/RequesTixApi";
import SuiteRequestForm from "../../../components/requests/suite/SuiteRequestForm";
import RouteConstants from "../../../constants/RouteConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { SuiteRequestSaveDTO } from "../../../types/SuiteRequest";
import { UserDTO } from "../../../types/User";

type EditSuiteRequestUrlProps = {
  suiteRequestId: string;
};

const NewSuiteRequest = () => {
  const navigate = useNavigate();
  const { suiteRequestId } = useParams<EditSuiteRequestUrlProps>();
  const { setLoading } = useLoading();
  const { userInfo } = useAuth();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserDTO | undefined>(undefined);
  const [suiteRequest, setSuiteRequest] = useState<SuiteRequestSaveDTO>(undefined);

  const getCurrentUser = async () => {
    try {
      const data: UserDTO = await getUser(userInfo.employeeUserId);
      setCurrentUser(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchRequest = async () => {
    try {
      const data: SuiteRequestSaveDTO = await getSuiteRequest(suiteRequestId);
      setSuiteRequest(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchRequest(), getCurrentUser()]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const save = async () => {
    try {
      setLoading(true);
      await saveSuiteRequest(suiteRequest);
      navigate(RouteConstants.REQUESTS.SUITES);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = () => {
    setSuiteRequest({});
    navigate(-1);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  return (
    <Card>
      <Card.Header>Suite Requests</Card.Header>
      <Card.Body>
        {!!suiteRequest && (
          <SuiteRequestForm
            suiteRequest={suiteRequest}
            setSuiteRequest={setSuiteRequest}
            setValidForm={setCanSave}
            currentUser={currentUser}
          />
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="primary" onClick={save} disabled={!canSave}>
            Submit
          </Button>
          <Button onClick={cancel}>Cancel</Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default NewSuiteRequest;
