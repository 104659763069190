import { Attribute, Icon, MobileView } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { ProjectUserTicketRequestDTO } from "./ProjectRequestsReport";
import { formatSubmittedDate } from "../../../util/TimeUtil";

const CommentText = styled.div.attrs(() => ({
  className: "ms-2",
}))`
  font-size: 0.875rem;
`;

const ProjectRequestDetails = ({ value }: { value: ProjectUserTicketRequestDTO }) => (
  <div className="d-flex flex-column flex-md-row">
    <div>
      <MobileView>
        <Attribute header="Requester" value={value.requester} />
        <Attribute header="Fulfillment" value={value.fulfillmentType} />
        <Attribute header="Total Ticket Cost" value={value.totalTicketCost ? `$${value.totalTicketCost}` : ""} />
        <Attribute header="Home Team" value={value.homeTeam} />
      </MobileView>
      <Attribute header="Recipient" value={value.recipient} />
      <Attribute header="Supervisor" value={value.supervisor} />
      <Attribute header="Submitter" value={value.submitter} />
      <Attribute header="Submitted" value={formatSubmittedDate(value.submitted)} />
    </div>
    {value.comments && (
      <div>
        <div className="ms-0 ms-md-4 d-flex align-items-center">
          <Icon iconName="fa-comment" />
          <CommentText>{value.comments}</CommentText>
        </div>
      </div>
    )}
  </div>
);

export default ProjectRequestDetails;
