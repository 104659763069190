import { Button } from "best-common-react";
import React from "react";
import { BillingDTO } from "../../types/UserBilling";
import CreditCard from "./CreditCard";
import NewCreditCardForm from "./NewCreditCardForm";

type UserBillingProps = {
  billings: BillingDTO[];
  userId: number | string;
  refresh: (value?: BillingDTO) => Promise<any>;
};

const UserBilling: React.FC<UserBillingProps> = ({ billings = [], userId, refresh }) => {
  const [showNewCreditCard, setShowNewCreditCard] = React.useState(false);

  const sortFunc = (a: BillingDTO, b: BillingDTO): number => {
    if (a.isDefault) {
      return -1;
    } else if (b.isDefault) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <div>
      {!showNewCreditCard && (
        <Button variant="link" onClick={() => setShowNewCreditCard(true)}>
          Add a card
        </Button>
      )}
      <NewCreditCardForm
        show={showNewCreditCard}
        onClose={() => setShowNewCreditCard(false)}
        userId={userId}
        refresh={refresh}
      />
      <div className="d-flex flex-wrap">
        {billings.sort(sortFunc).map((billing) => (
          <CreditCard key={`billing-${billing.userBillingId}`} userId={userId} refresh={refresh} {...billing} />
        ))}
      </div>
    </div>
  );
};

export default UserBilling;
