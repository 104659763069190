import {
  Button,
  Card,
  FormColumn,
  Row,
  Search,
  Select,
  sort,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getDepartments, getUsers, updateUserAllotment } from "../../../api/RequesTixApi";
import UserAllotmentBulkUpdate from "../../../components/user/UserAllotmentBulkUpdate";
import UserTable from "../../../components/user/UserTable";
import { useAdminYear } from "../../../contexts/AdminYearContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useMetadata } from "../../../contexts/MetadataContext";
import { AllotmentDTO, UserAllotmentDTO } from "../../../types/Allotment";
import { Department } from "../../../types/Department";
import { Season } from "../../../types/Season";
import { UserDTO } from "../../../types/User";
import AdminCreateUser from "./AdminCreateUser";

const AdminUsers = () => {
  const { loading, setLoading, setLoadingNonBlocking } = useLoading();
  const { activeSeason } = useMetadata();
  const { seasonsOptions } = useDropdowns();
  const [, setSelectedYear] = useAdminYear();

  const [users, setUsers] = useState<UserDTO[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [departments, setDepartments] = useState<ValueOpt<Department>[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<ValueOpt<Department | undefined>>({
    label: "",
    value: undefined,
  });
  const [selectedSeason, setSelectedSeason] = useState<ValueOpt<Season | undefined>>({
    label: "",
    value: undefined,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [updateAllotmentOpen, setUpdateAllotmentOpen] = useState<boolean>(false);
  const [createUserOpen, setCreateUserOpen] = useState<boolean>(false);

  const updateAllotment = async (allotment: AllotmentDTO[]) => {
    try {
      setLoading(true);
      const userAllotments: UserAllotmentDTO[] = selectedUsers.map((userId: number) => ({
        userId: userId,
        allotment: allotment,
      }));
      await updateUserAllotment(userAllotments);
      setUpdateAllotmentOpen(false);
      await getUserData();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getUserData = async () => {
    if (selectedSeason?.value) {
      try {
        setLoadingNonBlocking(true);
        const res: UserDTO[] = await getUsers(selectedDepartment?.value?.departmentId, selectedSeason?.value?.seasonId);
        setUsers(res);
        setSelectedUsers([]);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingNonBlocking(false);
      }
    }
  };

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const res: Department[] = await getDepartments();
      setDepartments([
        { label: "All", value: undefined } as ValueOpt<undefined>,
        ...sort(
          res.map(
            (department: Department) =>
              ({ label: department.departmentName, value: department } as ValueOpt<Department>)
          ),
          "label"
        ),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setUpdateAllotmentOpen(false);
    setSelectedUsers([]);
  };

  useEffect(() => {
    void fetchDepartments();
  }, []);

  useEffect(() => {
    if (departments.length > 0 && seasonsOptions.length > 0) {
      setSelectedDepartment(departments[0]);
      setSelectedSeason(seasonsOptions.find((season) => season.value.seasonId === activeSeason.seasonId) || undefined);
    }
  }, [departments, seasonsOptions]);

  useEffect(() => {
    if (selectedSeason?.value?.seasonId) {
      getUserData();
      setSelectedYear({ label: selectedSeason.value.year.toString(), value: selectedSeason.value.year });
    }
  }, [selectedDepartment, selectedSeason]);

  return (
    <Card>
      <Card.Header>Users</Card.Header>
      <Card.Body>
        {updateAllotmentOpen && (
          <UserAllotmentBulkUpdate
            isOpen={updateAllotmentOpen}
            onClose={onClose}
            onSave={updateAllotment}
            year={selectedSeason.value.year}
            userCount={selectedUsers.length}
          />
        )}
        <AdminCreateUser
          show={createUserOpen}
          close={(saved: boolean) => {
            if (saved) {
              getUserData();
            }
            setCreateUserOpen(false);
          }}
          departments={departments}
        />
        <Row>
          <FormColumn width={4}>
            <Select
              id="seasons-dropdown"
              label="Seasons"
              options={seasonsOptions}
              value={selectedSeason}
              onChange={(season) => setSelectedSeason(season)}
            />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="departments-dropdown"
              label="Departments"
              options={departments}
              value={selectedDepartment}
              onChange={(department) => setSelectedDepartment(department)}
            />
          </FormColumn>
          <FormColumn width={4}>
            <Search
              id="search-box"
              label="Search Users"
              placeholder="begin typing to filter users"
              value={searchTerm}
              onChange={setSearchTerm}
            />
          </FormColumn>
        </Row>
        <UserTable
          values={users}
          selected={selectedUsers}
          setSelected={setSelectedUsers}
          searchTerm={searchTerm}
          loading={loading}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={() => setCreateUserOpen(true)} className="me-2">
            Create User
          </Button>
          <Button variant="default" disabled={!selectedUsers.length} onClick={() => setUpdateAllotmentOpen(true)}>
            Update Allotments
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminUsers;
