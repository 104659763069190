import { Button, Card, FormColumn, Row, Search, Select, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequestsBySeriesNumber } from "../../../api/RequesTixApi";
import AdminRequestGame from "../../../components/requests/admin/AdminRequestGame";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { AdminRequestsDTO } from "../../../types/AdminRequest";
import { getCategoryOption, getCompleteOption, getStatusOption } from "../../../util/DropdownUtil";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { requestsFreeTextFilter } from "../../../util/TicketRequestUtil";

const defaultData: AdminRequestsDTO = {
  schedule: [],
  requests: {},
  games: {},
  totalRequests: {},
  gameList: [],
  ticketRequests: [],
};

type AdminSuiteRequestEditUrlProps = {
  homeTeamId?: string;
  seriesNumber?: string;
  season?: string;
  gameType?: string;
};

const AdminRequestsBySeries = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { homeTeamId, seriesNumber, season, gameType } = useParams<AdminSuiteRequestEditUrlProps>();
  const [data, setData] = useState<AdminRequestsDTO>(defaultData);
  const [filteredData, setFilteredData] = useState({});

  const { setLoading } = useLoading();
  const { requestCategoryOptions, requestStateOptions, completeOptions } = useDropdowns();

  const { statusId, categoryId, completeId, search } = useRouteParams(location.search);

  const category = getCategoryOption(categoryId, requestCategoryOptions);
  const status = getStatusOption(statusId, requestStateOptions);
  const complete = getCompleteOption(completeId, completeOptions);

  const updateUrl = ({ statusId, categoryId, completeId, search }) => {
    const url = urlReplace(RouteConstants.ADMIN.REQUESTS_SERIES, { homeTeamId, seriesNumber, season, gameType });
    updateRouteParams(
      navigate,
      url,
      {
        statusId: statusId,
        completeId: completeId,
        categoryId: categoryId,
        search: search,
      },
      { previousLocation: location.state.previousLocation }
    );
  };

  const updateValue = (key, value) => {
    updateUrl({
      statusId,
      categoryId,
      completeId,
      search,
      [key]: value,
    });
  };

  const close = () => {
    if (!!location?.state?.previousLocation) {
      navigate(location.state.previousLocation);
    } else {
      navigate(RouteConstants.ADMIN.REQUESTS);
    }
  };

  useEffect(() => {
    setLoading(true);
    getRequestsBySeriesNumber(season, homeTeamId, seriesNumber, gameType, statusId, categoryId, completeId)
      .then((resp: AdminRequestsDTO) => {
        setData(resp);
        setLoading(false);
      })
      .catch(() => {
        setData(defaultData);
      });
  }, [homeTeamId, seriesNumber, season, statusId, categoryId, completeId]);

  useEffect(() => {
    setFilteredData(requestsFreeTextFilter(data.requests, search));
  }, [data, search]);

  return (
    <Card>
      <Card.Header>Ticket Requests</Card.Header>
      <Card.Body>
        <Row className="row">
          <FormColumn width={4}>
            <Select
              id="status"
              label="Status"
              value={status}
              options={requestStateOptions}
              onChange={(value) => updateValue("statusId", value.value.requestStateId)}
              placeholder="select a status"
            />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="complete"
              label="Complete/Incomplete"
              value={complete}
              options={completeOptions}
              onChange={(value) => updateValue("completeId", value.value.id)}
              placeholder="select a complete status"
            />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="category"
              label="Category"
              value={category}
              options={requestCategoryOptions}
              onChange={(value) => updateValue("categoryId", value.value.requestCategoryId)}
              placeholder="select a category"
            />
          </FormColumn>
          <FormColumn width={4}>
            <Search
              id="search"
              label="Search Requests"
              value={search}
              onChange={(value) => updateValue("search", value)}
              placeholder="begin typing to filter requests"
            />
          </FormColumn>
        </Row>
        <div>
          {data.schedule.map((gamePk) => (
            <AdminRequestGame
              key={gamePk}
              game={data.games[gamePk]}
              requests={filteredData[gamePk]}
              totalRequests={data.totalRequests[gamePk]}
              isVisible={true}
            />
          ))}
        </div>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <Button variant="default" onClick={close}>
            Close
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminRequestsBySeries;
