import {
  DataTable,
  DataTableColumn,
  DataTableDataType,
  FormatterType,
  IconLinkFormatter,
  sort,
  SortFilters,
  tableSort,
  formatDate,
  isBefore,
  DATE_FORMATTER_STR,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import { useMetadata } from "../../contexts/MetadataContext";
import { VenueDTO } from "../../types/Venue";
import VenueAccordion from "./VenueAccordion";

const OffUntil = styled.span.attrs(() => ({
  className: "ms-2",
}))`
  color: ${(props) => props.theme.colors["slate-grey"]};
`;

export const BlackoutFormatter = ({ row: { blackoutStartDate, blackoutEndDate } }: FormatterType<VenueData>) => {
  const on = !blackoutStartDate && !blackoutEndDate;
  const before = isBefore(blackoutStartDate, new Date());
  let status = "";
  if (!on) {
    if (before) {
      status = `until ${formatDate(blackoutEndDate, DATE_FORMATTER_STR)}`;
    } else {
      status = `off from ${formatDate(blackoutStartDate, DATE_FORMATTER_STR)} - ${formatDate(
        blackoutEndDate,
        DATE_FORMATTER_STR
      )}`;
    }
  }
  return (
    <div>
      {on ? (
        <span>On</span>
      ) : (
        <>
          <span>{before ? "Off" : "On"}</span>
          <OffUntil>({status})</OffUntil>
        </>
      )}
    </div>
  );
};

type VenueTableProps = {
  values: VenueDTO[];
  searchTerm: string;
};

export type VenueData = DataTableDataType & {
  editLink: string;
  name: string;
  location: string;
  acceptedCCs: string;
  deliveryMethod: string;
  blackoutStartDate: Date;
  blackoutEndDate: Date;
};

const cols: DataTableColumn<VenueData>[] = [
  { key: "editLink", name: "", width: 35, readonlyFormatter: IconLinkFormatter, icon: "fa-pencil-alt", fixed: true },
  { key: "name", name: "Name", width: 250, sortable: true, fixed: true },
  { key: "location", name: "Location", width: 150, sortable: true },
  { key: "acceptedCCs", name: "Accepted Credit Cards", minWidth: 375, sortable: true },
  { key: "deliveryMethod", name: "Delivery Method", minWidth: 375, sortable: true },
  { key: "blackout", name: "Requests", minWidth: 500, sortable: true, readonlyFormatter: BlackoutFormatter },
];

function VenueTable({ values = [], searchTerm }: VenueTableProps) {
  const { cardTypes } = useMetadata();
  const [venues, setVenues] = useState<VenueData[]>([]);
  const [sortFilters, setSortFilters] = useState<SortFilters<VenueData>>({ key: "name", direction: "ASC" });

  useEffect(() => {
    const venues = values
      .map((venue) => {
        const allCards = venue.acceptedBillings.length === cardTypes.length;
        return {
          editLink: urlReplace(RouteConstants.ADMIN.VENUES_EDIT, { venueId: venue.id }),
          id: venue.id,
          name: venue.name,
          location: venue.state ? `${venue.city}, ${venue.state}` : venue.city,
          acceptedCCs: allCards
            ? "All"
            : sort(venue.acceptedBillings, "ccTypeId")
                .map((cc) => cc.ccTypeName)
                .join(", "),
          deliveryMethod: venue.deliveryMethods
            .map((method) => {
              if (!!method.deliveryMethod) {
                return method.deliveryMethod.deliveryMethod;
              } else {
                return "";
              }
            })
            .filter((dm) => !!dm.length)
            .join(", "),
          blackoutStartDate: venue.blackoutStartDate,
          blackoutEndDate: venue.blackoutEndDate,
        };
      })
      .filter((venue) => {
        return Object.values(venue).some((value) => {
          if (value) {
            return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return false;
          }
        });
      });
    setVenues(tableSort(venues, sortFilters));
  }, [values, cardTypes, searchTerm]);

  useEffect(() => {
    setVenues(tableSort(venues, sortFilters));
  }, [sortFilters]);

  return (
    <DataTable
      data={venues}
      columns={cols}
      sortFunction={(key, direction) => setSortFilters({ key, direction })}
      accordion={VenueAccordion}
      sortColumn={sortFilters["key"]}
      sortDirection={sortFilters["direction"]}
      statusTextOverride={(_selected, total) => `${total} Venues`}
    />
  );
}
export default VenueTable;
