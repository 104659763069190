import { DataTableDataType, FormatterType, TextFormatter } from "best-common-react";
import React from "react";

const PurchaseGameFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value } = props;
  const gameIds: number[] = value as number[];
  return <TextFormatter value={gameIds.map((g) => `Game: ${g}`).join(", ")} />;
};

export default PurchaseGameFormatter;
