import { Icon, Input, RequiredIndicator, Select, Table, ValueOpt } from "best-common-react";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { VenueDeliveryMethodOptions } from "../../types/Venue";

const Row = styled.tr`
  display: table;
  width: 100%;
  background-color: white;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 0.5rem;
  width: 5%;
  @media (max-width: 1200px) {
    width: 7.5%;
  }
  @media (max-width: 992px) {
    width: 10%;
  }
`;

const DescriptionHolder = styled.div`
  width: 75%;
  padding-left: 0.5rem;
  @media (max-width: 1200px) {
    width: 72.5%;
  }
  @media (max-width: 992px) {
    width: 70%;
  }
`;

const Headers = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
`;

const TableTd = styled.td.attrs(() => ({
  className: "p-1",
}))`
  &&& {
    border: none;
    border-top: 1px solid #dee2e6;
  }
`;

const TableRow = ({ deliveryMethod, index, onChange, onDelete }) => {
  const { venueDeliveryMethodsOptions } = useDropdowns();

  const getDropdownOpt = (venueDeliveryMethodsOptions, value) =>
    venueDeliveryMethodsOptions.find((opt) => !!value && opt.value.id === value.id);

  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {(provided) => (
        <Row {...provided.draggableProps} ref={provided.innerRef}>
          <TableTd>
            <div className="d-flex">
              <Icons>
                <Icon iconName="fa-grip-horizontal" {...provided.dragHandleProps} />
                <Icon iconName="fa-trash-alt" onClick={() => onDelete(deliveryMethod)} />
              </Icons>
              <div style={{ width: "20%", marginRight: ".5rem" }}>
                <Select
                  id="venue-delivery-method"
                  options={venueDeliveryMethodsOptions}
                  value={getDropdownOpt(venueDeliveryMethodsOptions, deliveryMethod.deliveryMethod)}
                  onChange={(value: ValueOpt<VenueDeliveryMethodOptions>) =>
                    onChange(index, "deliveryMethod", value.value)
                  }
                />
              </div>
              <DescriptionHolder style={{ marginRight: ".5rem" }}>
                <Input
                  id="venue-delivery-desc"
                  value={deliveryMethod.description}
                  onChange={(value: string) => onChange(index, "description", value)}
                />
              </DescriptionHolder>
            </div>
          </TableTd>
        </Row>
      )}
    </Draggable>
  );
};

const TableBody = ({ deliveryMethods, onChange, onDelete }) => (
  <>
    {deliveryMethods.map((deliveryMethod, index) => (
      <TableRow
        key={`row-${index}`}
        deliveryMethod={deliveryMethod}
        index={index}
        onChange={onChange}
        onDelete={onDelete}
      />
    ))}
  </>
);

const DeliveryMethodsDnD = ({ deliveryMethods, onReorder, onChange, onDelete }) => (
  <DragDropContext
    onDragEnd={(result) => {
      if (result && result.destination && result.source) onReorder(result.source.index, result.destination.index);
    }}
  >
    <Table className="table">
      <Table.Head>
        <Table.Row>
          <Headers>
            <Icons style={{ marginRight: ".5rem" }} />
            <div className="d-flex" style={{ width: "20%" }}>
              <div>Delivery Method</div>
              <RequiredIndicator />
            </div>
            <DescriptionHolder>
              <div className="d-flex">
                <div>Description</div>
                <RequiredIndicator />
              </div>
            </DescriptionHolder>
          </Headers>
        </Table.Row>
      </Table.Head>
      <Droppable droppableId="delivery-methods-dnd">
        {(provided) => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            <TableBody deliveryMethods={deliveryMethods} onChange={onChange} onDelete={onDelete} />
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </Table>
  </DragDropContext>
);

export default DeliveryMethodsDnD;
