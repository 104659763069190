import { FormColumn, Row, Select, Textarea, ValueOpt } from "best-common-react";
import React, { useEffect } from "react";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { StatsApiGame } from "../../../types/Stats";
import {
  AdminSuiteRequestStateDTO,
  SuiteRequestDTO,
  SuiteRequestStatus,
  SuiteStatuses,
} from "../../../types/SuiteRequest";
import AdminSuiteApprovedAction from "./AdminSuiteApprovedAction";
import AdminSuiteCompleteAction from "./AdminSuiteCompleteAction";
import AdminSuiteDeniedAction from "./AdminSuiteDeniedAction";
import AdminSuiteInReviewAction from "./AdminSuiteInReviewAction";

export type AdminSuiteActionsProps = {
  game?: StatsApiGame;
  request: SuiteRequestDTO;
  setRequest: (value: SuiteRequestDTO) => void;
  actionState: AdminSuiteRequestStateDTO;
  setActionState: (value: AdminSuiteRequestStateDTO) => void;
  saveRequest: (request: SuiteRequestDTO, actionState: AdminSuiteRequestStateDTO) => Promise<any>;
  refreshRequest: () => void;
};

const RenderActions = (props: AdminSuiteActionsProps) => {
  const { request } = props;
  switch (request.suiteRequestStatus.suiteStatusId) {
    case SuiteStatuses.APPROVED:
      return <AdminSuiteApprovedAction {...props} />;
    case SuiteStatuses.IN_REVIEW:
      return <AdminSuiteInReviewAction {...props} />;
    case SuiteStatuses.COMPLETE:
      return <AdminSuiteCompleteAction {...props} />;
    case SuiteStatuses.DENIED:
      return <AdminSuiteDeniedAction {...props} />;
    case SuiteStatuses.OPEN:
    default:
      return null;
  }
};

type AdminSuiteRequestsActionsProps = AdminSuiteActionsProps & {
  setValidActionState: (value: boolean) => void;
};

const AdminSuiteRequestsActions = ({
  game,
  request,
  setRequest,
  actionState,
  setActionState,
  setValidActionState,
  saveRequest,
  refreshRequest,
}: AdminSuiteRequestsActionsProps) => {
  const { suiteRequestStatusOptions } = useDropdowns();

  const changeRequest = (key: keyof SuiteRequestDTO, value: string | number | SuiteRequestStatus) => {
    setRequest({
      ...request,
      [key]: value,
    });
  };

  const changeAction = (key: keyof AdminSuiteRequestStateDTO, value: string | number) => {
    setActionState({
      ...actionState,
      [key]: value,
    });
  };

  useEffect(() => {
    //todo:
    const validActionState = !!actionState;
    setValidActionState(validActionState);
  }, [actionState]);

  return (
    <>
      <Row>
        <FormColumn width={2}>
          <Select
            id="suite-request-status"
            label="Status"
            options={suiteRequestStatusOptions}
            value={suiteRequestStatusOptions.find(
              (opt: ValueOpt<SuiteRequestStatus>) =>
                opt.value.suiteStatusId === request?.suiteRequestStatus?.suiteStatusId
            )}
            onChange={(value: ValueOpt<SuiteRequestStatus>) => changeRequest("suiteRequestStatus", value.value)}
            gutterBottom
          />
        </FormColumn>
        <RenderActions
          game={game}
          request={request}
          setRequest={setRequest}
          actionState={actionState}
          setActionState={setActionState}
          refreshRequest={refreshRequest}
          saveRequest={saveRequest}
        />
      </Row>
      <Row>
        <FormColumn width={1}>
          <Textarea
            id="admin-comments"
            label="Admin Comments"
            onChange={(value: string) => {
              changeAction("adminComments", value);
            }}
            value={actionState.adminComments}
            gutterBottom
          />
        </FormColumn>
      </Row>
    </>
  );
};

export default AdminSuiteRequestsActions;
