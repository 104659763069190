import { Button, Card, StickyFooterButtons, useLoading } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { createPurchaseEntry, getPurchaseOpportunity } from "../../api/RequesTixApi";
import PurchaseOpportunityCard from "../../components/purchaseOpportunity/PurchaseOpportunityCard";
import PurchaseOpportunityForm from "../../components/purchaseOpportunity/PurchaseOpportunityForm";
import RouteConstants from "../../constants/RouteConstants";
import { PurchaseOpportunityDTO, PurchaseOpportunityEntryRecordDTO } from "../../types/PurchaseOpportunity";

const Container = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

type RouteParams = {
  id: string;
};

const PurchaseOpportunityRequest: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<RouteParams>();
  const { setLoading } = useLoading();
  const [opportunity, setOpportunity] = useState<PurchaseOpportunityDTO | undefined>();
  const [entry, setEntry] = useState<PurchaseOpportunityEntryRecordDTO>({ purchaseOpportunityGameIds: [] });
  const [canSave, setCanSave] = useState<boolean>(false);

  const fetchPurchaseOpportunity = async (id: string) => {
    try {
      setLoading(true);
      const res: PurchaseOpportunityDTO = await getPurchaseOpportunity(id);
      setOpportunity(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const saveRequest = async () => {
    try {
      setLoading(true);
      await createPurchaseEntry(entry);
      navigate(RouteConstants.PURCHASE_OPPORTUNITY.LIST);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setEntry({
      ...entry,
      purchaseOpportunityId: parseInt(id),
    });
    void fetchPurchaseOpportunity(id);
  }, [id]);

  return (
    <Card>
      <Card.Header>Purchase Opportunity for Pair of Tickets</Card.Header>
      <Card.Body>
        <div className="mb-5">
          {!!opportunity && (
            <Container>
              <PurchaseOpportunityCard opp={opportunity} />
              <PurchaseOpportunityForm
                opportunity={opportunity}
                entry={entry}
                setEntry={setEntry}
                setValidForm={setCanSave}
              />
            </Container>
          )}
        </div>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={true}>
          <Button variant="primary" disabled={!canSave} onClick={saveRequest}>
            Submit
          </Button>
          <Button variant="default" onClick={cancel}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default PurchaseOpportunityRequest;
