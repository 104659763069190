import { addDays, addMonths, addWeeks, DATE_FORMATTER_STR, formatDate } from "best-common-react";

export const yesterday = addDays(new Date(), -1) as Date;
export const nextWeek = addWeeks(new Date(), 1) as Date;

export const previousMonth = addMonths(new Date(), -1) as Date;
export const nextMonth = addMonths(new Date(), 1) as Date;
export const todayStr = formatDate(new Date(), DATE_FORMATTER_STR);
export const nextWeekStr = formatDate(nextWeek, DATE_FORMATTER_STR);

export const previousMonthStr = formatDate(previousMonth, DATE_FORMATTER_STR);
export const nextMonthStr = formatDate(nextMonth, DATE_FORMATTER_STR);

export const yesterdayStr = formatDate(yesterday, DATE_FORMATTER_STR);
