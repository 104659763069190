import { DataTableDataType, FormatterType, Link, TextFormatter } from "best-common-react";
import React from "react";

export type ToLinkValue = DataTableDataType & {
  value: string | number | undefined;
  to: string;
  search?: string;
};

const ToLinkFormatter = <T extends DataTableDataType>({ value, ...props }: FormatterType<T>) => {
  const name = value ? value.value : "";
  const to = value ? value.to : "";
  const search = value ? value.search : "";
  if (to?.length) {
    return (
      <Link
        to={{
          pathname: to,
          search: search,
        }}
      >
        <TextFormatter {...props} value={name} />
      </Link>
    );
  } else {
    return <TextFormatter {...props} value={name} />;
  }
};

export default ToLinkFormatter;
