import { Button, groupBy, Hint, Modal, Select, StickyFooterButtons, Table, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { AllotmentAmountDTO, AllotmentDTO } from "../../types/Allotment";
import { RequestTypeDesc } from "../../types/RequestType";
import { Season } from "../../types/Season";
import UserAllotmentBulkUpdateTr from "./UserAllotmentBulkUpdateTr";

const DEFAULT_ALLOTMENT: AllotmentAmountDTO = {
  unlimited: false,
  amount: 0,
};

type UserAllotmentBulkUpdateProps = {
  isOpen?: boolean;
  year: number;
  onClose: () => void;
  onSave: (allotments: AllotmentDTO[]) => void;
  userCount: number;
};

const UserAllotmentBulkUpdate = ({
  isOpen = false,
  year,
  onClose,
  onSave,
  userCount,
}: UserAllotmentBulkUpdateProps) => {
  const { seasonsOptions: seasons } = useDropdowns();
  const [selectedSeason, setSelectedSeason] = useState<ValueOpt<Season[]> | undefined>(undefined);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [allotments, setAllotments] = useState<AllotmentDTO[]>([]);
  const [hasEdited, setHasEdited] = useState<boolean>(false);

  useEffect(() => {
    const seasonsOrdered: ValueOpt<Season>[] = seasons.sort((a, b) => (a.value.seasonId < b.value.seasonId ? -1 : 1));
    const seasonGroupBy: { [key: string]: Season[] } = groupBy(
      seasonsOrdered.map((season: ValueOpt<Season>) => season.value),
      "year"
    );
    const currentSeasonOptions: ValueOpt<Season[]>[] = Object.keys(seasonGroupBy)
      .map((seasonKey) => ({
        label: seasonKey,
        value: seasonGroupBy[seasonKey],
      }))
      .reverse();
    setSeasonOptions(currentSeasonOptions);

    const currentSelectedSeason: ValueOpt<Season[]> = currentSeasonOptions.find(
      (season) => year && season.label.toString() === year.toString()
    );
    setSelectedSeason(currentSelectedSeason);
  }, [seasons, year]);

  useEffect(() => {
    if (!!selectedSeason?.value) {
      const newAllotments = selectedSeason.value.map((season) => {
        const allotment: AllotmentDTO = {
          season,
          business: DEFAULT_ALLOTMENT,
          personal: DEFAULT_ALLOTMENT,
        };
        return allotment;
      });
      setAllotments(newAllotments);
    }
  }, [selectedSeason]);

  const changeAllotment = (
    index: number,
    type: RequestTypeDesc,
    unlimited: boolean,
    amount: number | undefined = 0
  ) => {
    if (!!allotments?.length) {
      const tempAllotments = [...allotments];
      tempAllotments[index][type] = {
        unlimited: unlimited,
        amount,
      };
      setAllotments(tempAllotments);
      setHasEdited(true);
    }
  };

  return (
    <>
      {!!isOpen && (
        <Modal show={isOpen} size="xl">
          <Modal.Header close={onClose}>Update Allotments</Modal.Header>
          <Modal.Body>
            <div>
              <div className="d-flex justify-content-between">
                <div style={{ width: 250 }}>
                  <Select
                    id="year"
                    label="Year"
                    options={seasonOptions}
                    value={selectedSeason}
                    onChange={(value: ValueOpt<Season[]>) => setSelectedSeason(value)}
                    gutterBottom
                    required
                  />
                </div>
                <Hint>{userCount} users selected</Hint>
              </div>
            </div>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Season</Table.Header>
                  <Table.Header>Personal</Table.Header>
                  <Table.Header>Business</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {selectedSeason?.value && (
                  <>
                    {selectedSeason.value.map((season, index) => (
                      <UserAllotmentBulkUpdateTr
                        allotments={allotments}
                        season={season}
                        index={index}
                        key={index}
                        changeAllotment={changeAllotment}
                      />
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <StickyFooterButtons>
              <Button
                variant="primary"
                disabled={!hasEdited}
                onClick={() => {
                  onSave(allotments);
                }}
              >
                Save
              </Button>
              <Button variant="default" onClick={onClose}>
                Cancel
              </Button>
            </StickyFooterButtons>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UserAllotmentBulkUpdate;
