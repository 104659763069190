import { ValueOpt } from "best-common-react";
import React, { createContext, useEffect, useState } from "react";
import { useDropdowns } from "./DropdownsContext";
import { useMetadata } from "./MetadataContext";

type AdminYearContextType = [year: ValueOpt<number>, setYear: (value: ValueOpt<number>) => void];

const AdminYearContext = createContext<AdminYearContextType | undefined>(null);

export function AdminYearProvider(props) {
  const { yearsOptions, getOptionByValue } = useDropdowns();
  const { activeSeason } = useMetadata();
  const [year, setYear] = useState<ValueOpt<any>>({
    label: new Date().getFullYear().toString(),
    value: new Date().getFullYear(),
  });

  useEffect(() => {
    if (activeSeason && yearsOptions && yearsOptions.length) {
      const defaultSelectedYear = getOptionByValue(yearsOptions, activeSeason.year);
      if (defaultSelectedYear) {
        setYear(defaultSelectedYear);
      }
    }
  }, [activeSeason, yearsOptions]);

  return <AdminYearContext.Provider value={[year, setYear]} {...props} />;
}

export function useAdminYear(initial?: ValueOpt<number>): AdminYearContextType {
  const context: AdminYearContextType | undefined = React.useContext(AdminYearContext);
  if (context === undefined) {
    throw new Error(`useAdminYear must be used within a AdminYearProvider`);
  }
  if (initial) {
    const [_year, setYear] = context;
    useEffect(() => {
      setYear(initial);
    }, []);
  }
  return context;
}
