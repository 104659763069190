import { ValueOpt } from "best-common-react";
import React, { createContext, useEffect, useMemo, useState } from "react";
import CompleteConstants from "../constants/CompleteConstants";
import GameQueryConstants from "../constants/GameQueryConstants";
import { APPROVED, DENIED, IN_REVIEW, OPEN } from "../constants/RequestTableConstants";
import { CreditCardType } from "../types/CreditCardType";
import { DigitalTicketType } from "../types/DigitalTickets";
import { Month } from "../types/Months";
import { SeriesTypeDTO } from "../types/PurchaseOpportunity";
import {
  ReportComplete,
  RequestCategory,
  RequestFulfillmentType,
  RequestState,
  RequestType,
} from "../types/RequestType";
import { Role } from "../types/Role";
import { Season } from "../types/Season";
import { State } from "../types/State";
import { SuiteRequestStatus } from "../types/SuiteRequest";
import { Team } from "../types/Team";
import { DayOfRequestStatus } from "../types/TodaysTickets";
import { UserBillingCountry } from "../types/UserBilling";
import { VenueDeliveryMethodOptions } from "../types/Venue";
import { getUserRoleText } from "../util/RoleUtil";
import { capitalizeFirstLetter } from "../util/StringUtil";
import { useMetadata } from "./MetadataContext";

type DropdownsContextType = {
  teamsOptions: ValueOpt<Team>[];
  currentSeasonOption: ValueOpt<Season | object>;
  seasonsOptions: ValueOpt<Season>[];
  yearsOptions: ValueOpt<number>[];
  rolesOptions: ValueOpt<Role>[];
  cardTypeOptions: ValueOpt<CreditCardType>[];
  stateOptions: ValueOpt<State>[];
  monthOptions: ValueOpt<Month>[];
  monthNumberOptions: ValueOpt<Month>[];
  creditYearOptions: ValueOpt<number>[];
  requestTypesOptions: ValueOpt<RequestType>[];
  requestFulfillmentTypesOptions: ValueOpt<number>[];
  requestCategoryOptions: ValueOpt<RequestCategory>[];
  requestStateOptions: ValueOpt<RequestState>[];
  requestStateFilterOptions: ValueOpt<number[]>[];
  hoursOptions: ValueOpt<number>[];
  quantityOptions: ValueOpt<number>[];
  yesNoOptions: ValueOpt<boolean>[];
  completeOptions: ValueOpt<ReportComplete>[];
  gameOptions: ValueOpt<number>[];
  getOptionByValue: (options: ValueOpt<any>[], value: any) => any;
  venueDeliveryMethodsOptions: ValueOpt<VenueDeliveryMethodOptions>[];
  billingCountryOptions: ValueOpt<UserBillingCountry>[];
  dayOfRequestStatusOptions: ValueOpt<DayOfRequestStatus>[];
  suiteRequestStatusOptions: ValueOpt<SuiteRequestStatus>[];
  seriesTypeOptions: ValueOpt<SeriesTypeDTO>[];
  digitalTicketTypeOptions: ValueOpt<DigitalTicketType>[];
};

const DropdownsContext = createContext<DropdownsContextType | undefined>(undefined);

export function DropdownsProvider(props) {
  const {
    teams,
    activeSeason,
    seasons,
    years,
    roles,
    states,
    cardTypes,
    months,
    creditYears,
    requestTypes,
    requestFulfillmentTypes,
    requestStates,
    requestCategories,
    venueDeliveryMethods,
    billingCountries,
    suiteRequestStatuses,
    seriesTypes,
    dayOfRequestStatuses,
  } = useMetadata();

  const getOptionByValue = (options: ValueOpt<any>[], value: any) => {
    if (options) {
      const res = options.find(
        (option) =>
          !!value &&
          (option.value === value ||
            (typeof option.value === "object" &&
              typeof value === "object" &&
              Object.keys(value).length === 0 &&
              Object.keys(option.value).length === 0))
      );
      return res ? res : [];
    } else {
      return [];
    }
  };

  const [teamsOptions, setTeamsOptions] = useState<ValueOpt<Team>[]>([]);
  useEffect(() => {
    setTeamsOptions([{ label: "All", value: {} }, ...teams.map((team: Team) => ({ label: team.name, value: team }))]);
  }, [teams]);

  const [currentSeasonOption, setCurrentSeasonOption] = useState<ValueOpt<Season | object>>({ label: "", value: {} });
  const [seasonsOptions, setSeasonsOptions] = useState<ValueOpt<Season>[]>([]);
  useEffect(() => {
    const options: ValueOpt<Season>[] = seasons.map((season: Season) => ({
      label: `${season.year} ${season.seasonType.seasonTypeName}`,
      value: season,
    }));
    setSeasonsOptions(options);
    setCurrentSeasonOption(options.find((option: ValueOpt<Season>) => option.value.seasonId === activeSeason.seasonId));
  }, [seasons, activeSeason]);

  const [yearsOptions, setYearsOptions] = useState<ValueOpt<number>[]>([]);
  useEffect(() => {
    setYearsOptions(
      years.map((year: number) => ({
        label: year.toString(),
        value: year,
      }))
    );
  }, [years]);

  const [rolesOptions, setRolesOptions] = useState<ValueOpt<Role>[]>([]);
  useEffect(() => {
    setRolesOptions(
      roles.map((role: Role) => ({
        label: getUserRoleText(role),
        value: role,
      }))
    );
  }, [roles]);

  const [stateOptions, setStateOptions] = useState<ValueOpt<State>[]>([]);
  useEffect(() => {
    setStateOptions(
      states.map((state: State) => ({
        label: state.code,
        value: state,
      }))
    );
  }, [states]);

  const [cardTypeOptions, setCardTypeOptions] = useState<ValueOpt<CreditCardType>[]>([]);
  useEffect(() => {
    setCardTypeOptions(
      cardTypes.map((cardType: CreditCardType) => ({
        label: cardType.ccTypeName,
        value: cardType,
      }))
    );
  }, [cardTypes]);

  const [monthNumberOptions, setMonthNumberOptions] = useState<ValueOpt<Month>[]>([]);
  useEffect(() => {
    setMonthNumberOptions(
      months.map((month: Month) => ({
        label: month.number.toString(),
        value: month,
      }))
    );
  }, [months]);

  const [monthOptions, setMonthOptions] = useState<ValueOpt<Month>[]>([]);
  useEffect(() => {
    setMonthOptions(
      months.map((month: Month) => ({
        label: month.abbreviation,
        value: month,
      }))
    );
  }, [months]);

  const [creditYearOptions, setCreditYearOptions] = useState<ValueOpt<number>[]>([]);
  useEffect(() => {
    setCreditYearOptions(
      creditYears.map((year: number) => ({
        label: year.toString(),
        value: year,
      }))
    );
  }, [creditYears]);

  const [requestTypesOptions, setRequestTypesOptions] = useState<ValueOpt<RequestType>[]>([]);
  React.useEffect(() => {
    setRequestTypesOptions(
      requestTypes.map((type: RequestType) => ({
        label: type.requestTypeName,
        value: type,
      }))
    );
  }, [requestTypes]);

  const [requestFulfillmentTypesOptions, setRequestFulfillmentTypesOptions] = useState<ValueOpt<number>[]>([]);
  React.useEffect(() => {
    setRequestFulfillmentTypesOptions(
      requestFulfillmentTypes.map((type: RequestFulfillmentType) => ({
        label: type.requestFulfillmentTypeName,
        value: type.requestFulfillmentTypeId,
      }))
    );
  }, [requestFulfillmentTypes]);

  const [hoursOptions, setHoursOptions] = useState<ValueOpt<number>[]>([]);
  React.useEffect(() => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      options.push({
        label: i,
        value: i,
      });
    }
    setHoursOptions(options);
  }, []);

  const [quantityOptions, setQuantityOptions] = useState<ValueOpt<number>[]>([]);
  React.useEffect(() => {
    const options = [];
    for (let i = 1; i <= 100; i++) {
      options.push({
        label: i,
        value: i,
      });
    }
    setQuantityOptions(options);
  }, []);

  const yesNoOptions: ValueOpt<boolean>[] = useMemo(
    () => [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
    []
  );

  const [requestCategoryOptions, setRequestCategoryOptions] = useState<ValueOpt<RequestCategory>[]>([]);
  React.useEffect(() => {
    setRequestCategoryOptions([
      { label: "All", value: {} },
      ...requestCategories.map((category: RequestCategory) => ({
        label: capitalizeFirstLetter(category.requestCategoryName),
        value: category,
      })),
    ]);
  }, [requestCategories]);

  const [requestStateOptions, setRequestStateOptions] = useState<ValueOpt<RequestState>[]>([]);
  React.useEffect(() => {
    setRequestStateOptions(
      requestStates.map((state: RequestState) => ({
        label: capitalizeFirstLetter(state.requestStateName),
        value: state,
      }))
    );
  }, [requestStates]);

  const [venueDeliveryMethodsOptions, setVenueDeliveryMethodsOptions] = useState<
    ValueOpt<VenueDeliveryMethodOptions>[]
  >([]);
  React.useEffect(() => {
    setVenueDeliveryMethodsOptions(
      venueDeliveryMethods.map((vdm: VenueDeliveryMethodOptions) => ({
        label: vdm.deliveryMethod,
        value: vdm,
      }))
    );
  }, [venueDeliveryMethods]);

  const [requestStateFilterOptions, setRequestStateFilterOptions] = useState<ValueOpt<number[]>[]>([]);
  React.useEffect(() => {
    const options = [
      { label: "All", value: [] },
      ...requestStates.map((state: RequestState) => ({
        label: capitalizeFirstLetter(state.requestStateName),
        value: [state.requestStateId],
      })),
    ];
    options.splice(3, 0, {
      label: "Open & In Review",
      value: [OPEN, IN_REVIEW],
    });
    options.push({ label: "Approved & Denied", value: [APPROVED, DENIED] });
    setRequestStateFilterOptions(options);
  }, [requestStates]);

  const completeOptions: ValueOpt<ReportComplete>[] = useMemo(
    () => [
      {
        label: "All",
        value: {},
      },
      {
        label: "Complete",
        value: { id: CompleteConstants.COMPLETE_ID },
      },
      {
        label: "Incomplete",
        value: { id: CompleteConstants.INCOMPLETE_ID },
      },
    ],
    []
  );

  const gameOptions: ValueOpt<number>[] = useMemo<ValueOpt<number>[]>(
    () => [
      {
        label: "All",
        value: null,
      },
      {
        label: "Future",
        value: GameQueryConstants.FUTURE_ID,
      },
      {
        label: "Past",
        value: GameQueryConstants.PAST_ID,
      },
    ],
    []
  );

  const [billingCountryOptions, setBillingCountryOptions] = useState<ValueOpt<UserBillingCountry>[]>([]);
  React.useEffect(() => {
    setBillingCountryOptions(
      billingCountries.map((bc: UserBillingCountry) => ({
        label: bc.name,
        value: bc,
      }))
    );
  }, [billingCountries]);

  const [suiteRequestStatusOptions, setSuiteRequestStatusOptions] = useState<ValueOpt<SuiteRequestStatus>[]>([]);
  React.useEffect(() => {
    setSuiteRequestStatusOptions(
      suiteRequestStatuses.map((bc: SuiteRequestStatus) => ({
        label: bc.status,
        value: bc,
      }))
    );
  }, [suiteRequestStatuses]);

  const [seriesTypeOptions, setSeriesTypeOptions] = useState<ValueOpt<SeriesTypeDTO>[]>();
  React.useEffect(() => {
    setSeriesTypeOptions(
      seriesTypes.map((s: SeriesTypeDTO) => ({
        label: s.description,
        value: s,
      }))
    );
  }, [seriesTypes]);

  const [dayOfRequestStatusOptions, setDayOfRequestStatusOptions] = useState<ValueOpt<DayOfRequestStatus>[]>([]);
  React.useEffect(() => {
    setDayOfRequestStatusOptions(
      dayOfRequestStatuses.map((d: DayOfRequestStatus) => ({
        label: d.dayOfRequestStatus,
        value: d,
      }))
    );
  }, [dayOfRequestStatuses]);

  const digitalTicketTypeOptions: ValueOpt<DigitalTicketType>[] = useMemo(
    () => [
      {
        label: "All",
        value: undefined,
      },
      {
        label: "Today's Ticket",
        value: "Today's Ticket",
      },
      {
        label: "Ticket Request",
        value: "Ticket Request",
      },
      {
        label: "Suite Request",
        value: "Suite Request",
      },
    ],
    []
  );

  return (
    <DropdownsContext.Provider
      value={{
        teamsOptions,
        currentSeasonOption,
        seasonsOptions,
        yearsOptions,
        rolesOptions,
        cardTypeOptions,
        stateOptions,
        monthOptions,
        monthNumberOptions,
        creditYearOptions,
        requestTypesOptions,
        requestFulfillmentTypesOptions,
        requestCategoryOptions,
        requestStateOptions,
        requestStateFilterOptions,
        hoursOptions,
        quantityOptions,
        yesNoOptions,
        completeOptions,
        gameOptions,
        getOptionByValue,
        venueDeliveryMethodsOptions,
        billingCountryOptions,
        dayOfRequestStatusOptions,
        suiteRequestStatusOptions,
        seriesTypeOptions,
        digitalTicketTypeOptions,
      }}
      {...props}
    />
  );
}

export function useDropdowns(): DropdownsContextType {
  const context: DropdownsContextType | undefined = React.useContext(DropdownsContext);
  if (context === undefined) {
    throw new Error(`useDropdowns must be used within a DropdownsProvider`);
  }
  return context;
}
