import { useOktaAuth } from "@okta/okta-react";
import { Button, IconAdorner } from "best-common-react";
import React, { useEffect } from "react";
import styled from "styled-components";
import navLogo from "../assets/requesttix-logo.png";

const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const TeamIds: number[] = shuffleArray([
  108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 133, 134, 135, 136, 137, 138, 139, 140, 141,
  142, 143, 144, 145, 146, 147, 158,
]);

const BGHolder = styled.div`
  position: fixed;
  width: 100vw;
  margin-top: 20px;
  height: calc(100vh - 20px);
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1050;
`;

const LogoHolder = styled.div.attrs(() => ({
  className: "d-flex align-items-center justify-content-center",
}))`
  width: 20vw;
  height: 16vh;
  opacity: 0.1;
`;

const Image = styled.img`
  width: 75%;
  height: 75%;
`;

const SignInHolder = styled.div.attrs(() => ({
  className: "d-flex justify-content-center",
}))`
  margin-top: 100px;
`;

const SignIn = styled.div.attrs(() => ({
  className: "d-flex flex-column align-items-center",
}))`
  &&& {
    width: 395px;
    height: 285px;
    border-radius: 8px;
    border: solid 1px #ccc9c9;
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 1;
    z-index: 1051;
  }
`;

const LogoImage = styled.img.attrs(() => ({
  className: "my-5",
}))`
  width: 295px;
  height: 66.4px;
`;

type LogoProps = {
  id: number;
};

const Logo: React.FC<LogoProps> = ({ id }) => (
  <LogoHolder>
    <Image alt="team logo" src={`https://www.mlbstatic.com/team-logos/${id}.svg`} />
  </LogoHolder>
);

const BG: React.FC = () => {
  const bg = [];

  for (let i = 0; i < 30; i++) {
    bg.push(<Logo key={TeamIds[i]} id={TeamIds[i]} />);
  }

  return (
    <BGHolder>
      <div className="d-flex flex-wrap">{bg}</div>
    </BGHolder>
  );
};

const Login: React.FC = () => {
  const { oktaAuth } = useOktaAuth();

  const loginFunc = (): void => {
    oktaAuth.signInWithRedirect({ originalUri: window.location.origin });
  };

  useEffect(() => {
    const path = window.location.pathname + window.location.search;
    oktaAuth.setOriginalUri(path);
  }, []);

  return (
    <div>
      <BG />
      <SignInHolder>
        <SignIn>
          <LogoImage src={navLogo} alt="Logo" />
          <Button
            variant="primary"
            onClick={loginFunc}
            endAdorner={<IconAdorner iconName="fa-key" styles={{ color: "white" }} />}
          >
            Sign In With MLB SSO
          </Button>
        </SignIn>
      </SignInHolder>
    </div>
  );
};

export default Login;
