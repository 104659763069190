import { Button, Modal } from "best-common-react";
import React from "react";
import styled from "styled-components";

const ConfirmationText = styled.div`
  font-size: 18px;
  font-weight: 300;
`;

const OverrideDeleteModal = ({ department = "", show, onDelete, onClose }) => {
  return (
    <Modal show={show} size="xl">
      <Modal.Header close={onClose}>Delete Override(s)</Modal.Header>
      <Modal.Body>
        <ConfirmationText>
          Are you sure you want to delete the override(s) for <span style={{ fontWeight: "bold" }}>{department}</span>?
        </ConfirmationText>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="me-2" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OverrideDeleteModal;
