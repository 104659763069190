import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  Button,
  Card,
  CustomMobileAccordionType,
  DataTable,
  DataTableDataType,
  FormColumn,
  Icon,
  IconLinkFormatter,
  Link,
  Row,
  Search,
  Select,
  SortFilters,
  StickyFooterButtons,
  tableSort,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { graphQl } from "../../../api/RequesTixApi";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";

type ProjectQL = {
  projectId: number;
  name: string;
  description: string;
  active: boolean;
  users: {
    employeeUserId: number;
  }[];
};

type ProjectData = DataTableDataType & {
  id: number;
  editLink: string;
  name: string;
  description: string;
  users: number;
  active: boolean;
};

const createQuery = () => `{
  getAllProjects{
    projectId,
    name,
    description,
    active,
    users {
      employeeUserId,
    }
  }
}
`;

const IsActiveFormatter = ({ value }) => value && <Icon iconName="fa fa-check" />;

const Columns = [
  {
    name: "",
    key: "editLink",
    width: 35,
    readonlyFormatter: IconLinkFormatter,
    icon: "fa-pencil-alt",
    fixed: true,
  },
  {
    name: "Name",
    key: "name",
    sortable: true,
    width: 250,
    fixed: true,
  },
  {
    name: "Users",
    key: "users",
    width: 150,
    cellClass: "justify-content-center",
    sortable: true,
  },
  {
    name: "Active",
    key: "active",
    width: 100,
    cellClass: "justify-content-center",
    readonlyFormatter: IsActiveFormatter,
  },
  {
    name: "Description",
    key: "description",
    sortable: true,
    minWidth: 250,
  },
];

const AdminProjectAccordion = ({
  data: { name, users, active, description, editLink },
  ...rest
}: CustomMobileAccordionType<ProjectData>) => (
  <Accordion {...rest}>
    <Accordion.Header>
      <Link to={editLink}>
        <Icon iconName="fa-pencil-alt" className="fa-lg me-3" />
      </Link>
      {name}
    </Accordion.Header>
    <Accordion.Body>
      <div>
        <AttributeHeader>Users</AttributeHeader>
        <AttributeValue>{users}</AttributeValue>
      </div>
      <div>
        <AttributeHeader>Active</AttributeHeader>
        <AttributeValue>
          <IsActiveFormatter value={active} />
        </AttributeValue>
      </div>
      <div>
        <AttributeHeader>Description</AttributeHeader>
        <AttributeValue>{description}</AttributeValue>
      </div>
    </Accordion.Body>
  </Accordion>
);

const filter = (projects: ProjectData[], search: string, active: boolean): ProjectData[] => {
  return projects
    .filter((p) => p.active === active)
    .filter((p) => {
      if (search && search.length) {
        return (
          p.name.toLowerCase().includes(search.toLowerCase()) ||
          p.description.toLowerCase().includes(search.toLowerCase())
        );
      } else {
        return true;
      }
    });
};

const ActiveInactiveOpts: ValueOpt<number>[] = [
  {
    label: "All",
    value: -1,
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 2,
  },
];

const AdminProject = () => {
  const navigate = useNavigate();
  const { loading, setLoadingNonBlocking } = useLoading();
  const [active, setActive] = useState<ValueOpt<number>>(ActiveInactiveOpts[1]);
  const [search, setSearch] = useState<string>("");
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [visibleProjects, setVisibleProjects] = useState([]);
  const [sortFilters, setSortFilters] = React.useState<SortFilters<ProjectData>>({ key: "name", direction: "ASC" });

  const createNewProject = (id: number | string) => {
    navigate(urlReplace(RouteConstants.ADMIN.PROJECTS_EDIT, { projectId: id }));
  };

  const fetchProjects = async () => {
    try {
      setLoadingNonBlocking(true);
      const queryObj = {
        query: createQuery(),
        variables: null,
        operationName: null,
      };
      const data = await graphQl(queryObj);
      if (data) {
        const projs: ProjectQL[] = data.getAllProjects;
        setProjects(
          projs.map((d: ProjectQL) => ({
            id: d.projectId,
            editLink: urlReplace(RouteConstants.ADMIN.PROJECTS_EDIT, { projectId: d.projectId }),
            name: d.name,
            description: d.description,
            users: d.users ? d.users.length : 0,
            active: d.active,
          }))
        );
      } else {
        setProjects([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  useEffect(() => {
    void fetchProjects();
  }, []);

  useEffect(() => {
    setVisibleProjects(tableSort(filter(projects, search, active.value === 1), sortFilters));
  }, [sortFilters, projects, search, active]);

  return (
    <Card>
      <Card.Header>Projects</Card.Header>
      <Card.Body>
        <Row>
          <FormColumn width={4}>
            <Select
              id="activeInactive"
              label="Active/Inactive"
              options={ActiveInactiveOpts}
              value={active}
              onChange={(value: ValueOpt<number>) => setActive(value)}
              placeholder="Active/Inactive"
            />
          </FormColumn>
          <FormColumn width={4}>
            <Search
              id="search"
              label="search"
              value={search}
              onChange={setSearch}
              placeholder="begin typing to filter projects"
              gutterBottom
            />
          </FormColumn>
        </Row>
        <DataTable
          accordion={AdminProjectAccordion}
          columns={Columns}
          data={visibleProjects}
          sortColumn={sortFilters["key"]}
          sortDirection={sortFilters["direction"]}
          sortFunction={(key, direction) => setSortFilters({ key, direction })}
          statusTextOverride={(_selected, total) => `${total} Projects`}
          loading={loading}
        />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <Button variant="default" onClick={() => createNewProject(-1)}>
            Create New Project
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminProject;
