import { BCRSuspense } from "best-common-react";
import React, { useEffect, useState } from "react";
import { StatsApiGame } from "../../../../types/Stats";
import { getGameFromGamePk } from "../../../../util/GameUtil";
import SimpleGameMatchup from "../../../game/SimpleGameMatchup";

type GameDisplayProps = {
  gamePk?: number;
  setGame?: (value: StatsApiGame) => void;
};

const GameDisplay: React.FC<GameDisplayProps> = ({ gamePk, setGame }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<StatsApiGame | undefined>();

  const fetchGame = async (value: number) => {
    try {
      setLoading(true);
      const game: StatsApiGame = await getGameFromGamePk(value);
      setSelectedGame(game);
      if (!!setGame) {
        setGame(game);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!gamePk) {
      void fetchGame(gamePk);
    }
  }, [gamePk]);

  return !!selectedGame ? (
    <div>
      {!loading ? (
        <SimpleGameMatchup
          gameDate={selectedGame.gameDate}
          homeTeamId={selectedGame.teams.home.team.id}
          awayTeamId={selectedGame.teams.away.team.id}
        />
      ) : (
        <BCRSuspense />
      )}
    </div>
  ) : null;
};

export default GameDisplay;
