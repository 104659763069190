import { Textarea } from "best-common-react";
import React from "react";

const AdminComments = ({ state, onStateChange }) => (
  <Textarea
    id="admin-comments"
    label="Admin Comments"
    value={state.adminComments ? state.adminComments : ""}
    placeholder="add a comment..."
    onChange={(value: string) => onStateChange({ ...state, adminComments: value })}
    gutterBottom
  />
);

export default AdminComments;
