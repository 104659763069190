import { Select, ValueOpt } from "best-common-react";
import React from "react";
import { getUserProjects } from "../../../api/RequesTixApi";
import { User } from "../../../types/User";

type TicketRequestProjectSelectProps = {
  submitter: User;
  projectId?: number | null;
  setProjectId: (value?: number | null) => void;
  setHide: (value: boolean) => void;
};

const TicketRequestProjectSelect: React.FC<TicketRequestProjectSelectProps> = ({
  submitter,
  projectId,
  setProjectId,
  setHide,
}) => {
  const [options, setOptions] = React.useState<ValueOpt<number | null>[]>([]);
  React.useEffect(() => {
    getUserProjects(submitter.employeeUserId).then((res) => {
      if (res.length > 0) {
        setOptions([
          ...res.map((project) => ({
            label: project.name,
            value: project.projectId,
          })),
          {
            label: "None",
            value: null,
          },
        ]);
      } else {
        setProjectId(null);
        setHide(true);
      }
    });
  }, [submitter]);

  return (
    <Select
      id="project"
      required
      label="Project"
      placeholder="select a project"
      options={options}
      value={options.find(({ value }) => value === projectId)}
      onChange={({ value }: ValueOpt<number | null>) => setProjectId(value)}
      clearable={true}
      gutterBottom
    />
  );
};

export default TicketRequestProjectSelect;
