import { Button, Modal } from "best-common-react";
import React from "react";

type CancelConfirmation = {
  show?: boolean;
  onClose: () => void;
  onCancel: () => void;
};

const CancelConfirmation: React.FC<CancelConfirmation> = ({ show = false, onClose, onCancel }) => (
  <Modal show={show}>
    <Modal.Header close={onClose}>Are you sure?</Modal.Header>
    <Modal.Body>
      <div>You have unsaved changes that will be lost.</div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" className="me-2" onClick={onCancel}>
        Discard Changes
      </Button>
      <Button variant="default" onClick={onClose}>
        Back
      </Button>
    </Modal.Footer>
  </Modal>
);

export default CancelConfirmation;
