import {
  Accordion,
  Attribute,
  CustomMobileAccordionType,
  DataTable,
  DataTableColumn,
  FormatterType,
  Icon,
  SubSection,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import { getAllDayOfTickets } from "../../../api/RequesTixApi";
import { AdminDayOfTicket } from "../../../types/TodaysTickets";
import GameDateFormatter from "../../tables/GameDateFormatter";
import DeleteTodayTicketModal from "./DeleteTodayTicketModal";

const AdminActiveTodaysTicketsTable: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tickets, setTickets] = useState<AdminDayOfTicket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<AdminDayOfTicket | undefined>(undefined);

  const DeleteFormatter: React.FC<FormatterType<AdminDayOfTicket>> = ({ row, className }) => (
    <Icon iconName="fa-trash-alt" onClick={() => setSelectedTicket(row)} className={className} />
  );

  const AdminDayOfTicketAccordion: React.FC<React.PropsWithChildren<CustomMobileAccordionType<AdminDayOfTicket>>> = ({
    data,
    ...rest
  }) => {
    const { game, gamePk, ticketId, seat, row, section } = data as AdminDayOfTicket;
    return (
      <Accordion {...rest}>
        <Accordion.Header>
          <div className="d-flex">
            <DeleteFormatter value={seat} row={data} className="me-2" />
            <GameDateFormatter value={game} row={data} />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            <Attribute header="GamePk" value={gamePk} />
            <Attribute header="Ticket ID" value={ticketId} />
            <Attribute header="Section" value={section} />
            <Attribute header="Row" value={row} />
            <Attribute header="Seat" value={seat} />
          </div>
        </Accordion.Body>
      </Accordion>
    );
  };

  const Cols: DataTableColumn<AdminDayOfTicket>[] = [
    { key: "seat", name: "", width: 35, readonlyFormatter: DeleteFormatter, icon: "fa-trash-alt", fixed: true },
    { key: "game", name: "Game", width: 400, readonlyFormatter: GameDateFormatter },
    { key: "gamePk", name: "Game PK", minWidth: 200 },
    { key: "ticketId", name: "Ticket ID", minWidth: 300 },
    { key: "section", name: "Section", minWidth: 150 },
    { key: "row", name: "Row", width: 150 },
    { key: "seat", name: "Seat", minWidth: 150 },
  ];

  const getAllTickets = async () => {
    try {
      setLoading(true);
      const result: AdminDayOfTicket[] = await getAllDayOfTickets();
      setTickets(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getAllTickets();
  }, []);

  return (
    <div className="mt-2">
      <SubSection header="Current Today's Tickets">
        <DeleteTodayTicketModal
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          refresh={getAllTickets}
        />
        <DataTable
          data={tickets}
          columns={Cols}
          loading={loading}
          statusTextOverride={(_selected, total) => `${total} Today's Tickets`}
          accordion={AdminDayOfTicketAccordion}
        />
      </SubSection>
    </div>
  );
};

export default AdminActiveTodaysTicketsTable;
