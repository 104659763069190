import { Accordion, AttributeHeader, AttributeValue, CustomMobileAccordionType, Table } from "best-common-react";
import React from "react";
import ToLinkFormatter from "../formatters/ToLinkFormatter";
import { AllProjectRequestData } from "./ProjectRequestsAllProjects";

const ProjectRequestsAllProjectsAccordion = ({
  data: request,
  ...rest
}: CustomMobileAccordionType<AllProjectRequestData>) => (
  <Accordion {...rest}>
    <Accordion.Header>
      <ToLinkFormatter value={request.projectNameObj} />
    </Accordion.Header>
    <Accordion.Body>
      <div>
        <AttributeHeader>Users</AttributeHeader>
        <AttributeValue>{request.userCount}</AttributeValue>
      </div>
      <div>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header />
              <Table.Header>Business</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TdInfo>Approved</Table.TdInfo>
              <Table.Td>
                <ToLinkFormatter value={request.approved} />
              </Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Comp</Table.TdInfo>
              <Table.Td>
                <ToLinkFormatter value={request.comped} />
              </Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Card</Table.TdInfo>
              <Table.Td>
                <ToLinkFormatter value={request.card} />
              </Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Dept</Table.TdInfo>
              <Table.Td>
                <ToLinkFormatter value={request.dept} />
              </Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Denied</Table.TdInfo>
              <Table.Td>
                <ToLinkFormatter value={request.denied} />
              </Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Total</Table.TdInfo>
              <Table.Td>
                <ToLinkFormatter value={request.total} />
              </Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Card Total</Table.TdInfo>
              <Table.Td>{request.cardTotalCost}</Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Dept Total</Table.TdInfo>
              <Table.Td>{request.deptTotalCost}</Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Total Cost</Table.TdInfo>
              <Table.Td>{request.totalCost}</Table.Td>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Accordion.Body>
  </Accordion>
);

export default ProjectRequestsAllProjectsAccordion;
