import { DATE_FORMATTER_STR_WITH_TIME, formatDate, Select, ValueOpt } from "best-common-react";
import React, { useEffect } from "react";
import { components, OptionProps, SingleValueProps } from "react-select";
import styled from "styled-components";
import { BillingDTO } from "../../types/UserBilling";
import NewCreditCardModal from "../payment/NewCreditCardModal";
import CreditCardDisplay from "./CreditCardDisplay";

const AddNewCard = styled.span`
  margin-left: 1.5rem;
  color: rgb(76, 140, 238);

  &:hover {
    cursor: pointer;
  }
`;

const Option = ({ data, ...rest }: OptionProps<ValueOpt<BillingDTO>>) => {
  const { value } = data ?? {};
  return (
    <components.Option className="p-1" data={data} {...rest}>
      <CreditCardDisplay card={value as BillingDTO} />
    </components.Option>
  );
};

const Value = ({ data, ...rest }: SingleValueProps<ValueOpt<BillingDTO>>) => {
  const { value } = data ?? {};
  return (
    <components.SingleValue className="p-1" data={data} {...rest}>
      <CreditCardDisplay card={value as BillingDTO} />
    </components.SingleValue>
  );
};

type CreditCardSelectProps = {
  id: string;
  userBillings: BillingDTO[];
  selected: BillingDTO;
  setSelected: (value: BillingDTO) => void;
  userId?: number;
  canAddCard?: boolean;
  onNewCardAdded?: (value: BillingDTO) => void;
  chargedDate?: Date | string;
  required?: boolean;
  disabled?: boolean;
  gutterBottom?: boolean;
};

const CreditCardSelect: React.FC<CreditCardSelectProps> = ({
  id,
  userBillings = [],
  selected = {},
  setSelected,
  userId,
  canAddCard = false,
  onNewCardAdded,
  chargedDate,
  required = false,
  disabled = false,
  gutterBottom = false,
}) => {
  const [showAddCardModal, setShowAddCardModal] = React.useState(false);
  const [options, setOptions] = React.useState<ValueOpt<BillingDTO>[]>([]);
  const [selectDisabled, setSelectDisabled] = React.useState(disabled);

  const onNewCardClose = (userBilling?: BillingDTO) => {
    setShowAddCardModal(false);
    if (!!userBilling && !!onNewCardAdded) {
      onNewCardAdded(userBilling);
    }
  };

  useEffect(() => {
    setSelectDisabled(userBillings.length === 0 || disabled);
    setOptions(
      userBillings.map((cc: BillingDTO) => ({
        label: `${cc.ccNickname} ending in ${cc.ccLast4}`,
        value: cc,
      }))
    );
  }, [userBillings]);

  useEffect(() => {
    if (disabled !== selectDisabled) {
      setSelectDisabled(disabled);
    }
  }, [disabled]);

  return (
    <>
      <NewCreditCardModal show={showAddCardModal} onClose={onNewCardClose} userId={userId} />
      <Select
        id={id}
        label={
          <div>
            Credit Card
            <span>
              {chargedDate
                ? ` charged ${formatDate(new Date(chargedDate as string), DATE_FORMATTER_STR_WITH_TIME)}`
                : ""}
            </span>
            {!!canAddCard && <AddNewCard onClick={() => setShowAddCardModal(true)}>Add a card</AddNewCard>}
          </div>
        }
        required={required}
        disabled={selectDisabled}
        options={options}
        value={
          options.find(
            (cc: ValueOpt<BillingDTO>) => !!cc.value && !!selected && cc.value.userBillingId === selected?.userBillingId
          ) || []
        }
        onChange={(value: ValueOpt<BillingDTO>) => setSelected(value.value)}
        components={{ Option: Option, SingleValue: Value }}
        searchable={true}
        gutterBottom={gutterBottom}
      />
    </>
  );
};

export default CreditCardSelect;
