import { NavigationConfigType, NavigationV2, sort } from "best-common-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReports } from "../../api/RequesTixApi";
import navLogo from "../../assets/requesttix-logo.png";
import GameQueryConstants from "../../constants/GameQueryConstants";
import RouteConstants from "../../constants/RouteConstants";
import { useAuth } from "../../contexts/AuthContext";
import { useEnv } from "../../contexts/EnvContext";
import { useMetadata } from "../../contexts/MetadataContext";
import { urlReplace } from "../../util/RouteUtil";

const Nav = () => {
  const navigate = useNavigate();
  const { isProd, loggedIn, userInfo, isAdmin, logout } = useAuth();
  const { refreshMetadata } = useMetadata();
  const [reportSubLinks, setReportSubLinks] = useState(null);
  const {
    variables: { ENV = "prod" },
  } = useEnv();

  const NavLogout = () => {
    logout();
    navigate(RouteConstants.LOGIN);
  };

  const GoToAccount = () => {
    navigate(RouteConstants.USER.ACCOUNT);
  };

  useEffect(() => {
    if (userInfo) {
      getReports().then((data) => {
        setReportSubLinks(
          sort(data, "reportName").map((d) => ({
            name: d.reportName,
            to: urlReplace(RouteConstants.REPORTS, { reportId: d.reportId }) + `?userId=${userInfo.employeeUserId}`,
          }))
        );
      });
    }
  }, [userInfo]);

  const config: NavigationConfigType = useMemo(
    () => ({
      env: ENV,
      displayEnvIndicator: !isProd,
      loggedIn: !!loggedIn,
      username: userInfo.email,
      startLogo: {
        logo: navLogo,
        link: RouteConstants.BASE,
        visible: true,
      },
      title: "",
      builds: [],
      tabletSupport: false,
      usernameDropdownLinks: [
        {
          name: "My Account",
          onClick: GoToAccount,
        },
        {
          name: "Refresh Metadata",
          onClick: () => refreshMetadata(true),
        },
        {
          name: "Sign Out",
          to: RouteConstants.LOGIN,
          onClick: NavLogout,
        },
      ],
      navigationLinks: [
        {
          name: "Requests",
          subLinks: [
            {
              name: "Request Tickets",
              to: RouteConstants.BASE,
            },
            {
              name: "Purchase Opportunities",
              to: RouteConstants.PURCHASE_OPPORTUNITY.LIST,
            },
            {
              name: "My Requests",
              to: `${RouteConstants.REQUESTS.BASE}?games=${GameQueryConstants.FUTURE_ID}`,
            },
            {
              name: "My Suite Requests",
              to: RouteConstants.REQUESTS.SUITES,
            },
          ],
        },
        {
          name: "Today's Tickets",
          to: RouteConstants.TODAYS_TICKETS,
        },
        {
          name: "Reports",
          subLinks: reportSubLinks,
        },
        {
          name: "Admin",
          visible: isAdmin,
          subLinks: [
            {
              name: "Ticket Requests",
              to: RouteConstants.ADMIN.REQUESTS,
              visible: isAdmin,
            },
            {
              name: "Departments",
              to: RouteConstants.ADMIN.DEPARTMENTS,
              visible: isAdmin,
            },
            {
              name: "Digital Tickets",
              to: RouteConstants.ADMIN.DIGITAL_TICKETS,
              visible: isAdmin,
            },
            {
              name: "Events",
              to: RouteConstants.ADMIN.EVENTS,
              visible: isAdmin,
            },
            {
              name: "Projects",
              to: RouteConstants.ADMIN.PROJECTS,
              visible: isAdmin,
            },
            {
              name: "Purchase Opportunities",
              to: RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.BASE,
              visible: isAdmin,
            },
            {
              name: "Suite Requests",
              to: RouteConstants.ADMIN.SUITE_REQUESTS.BASE,
              visible: isAdmin,
            },
            {
              name: "TDC",
              to: RouteConstants.ADMIN.TDC,
              visible: false,
            },
            {
              name: "Today's Tickets",
              to: RouteConstants.ADMIN.TODAYS_TICKETS,
              visible: isAdmin,
            },
            {
              name: "Users",
              to: RouteConstants.ADMIN.USERS,
              visible: isAdmin,
            },
            {
              name: "Venues",
              to: RouteConstants.ADMIN.VENUES,
              visible: isAdmin,
            },
          ],
        },
      ],
    }),
    [reportSubLinks]
  );

  return <NavigationV2 config={config} />;
};

export default Nav;
